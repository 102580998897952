






import Vue from 'vue';
import Homepage from './views/Homepage.vue';

declare global {
  interface Window {
    lastFocusedEl: string;
  }
}

export default Vue.extend({
  name: 'app',
  components: {
    Homepage,
  },
  mounted: function(): void {
    this.setInitialTheme();
  },
  methods: {
    setInitialTheme(): void {
      let setTheme = 'light';
      let cachedTheme = localStorage.getItem('theme');
      if (cachedTheme) {
        setTheme = cachedTheme;
      } else if (matchMedia('(prefers-color-scheme: dark)').matches) {
        setTheme = 'dark';
      }
      document.documentElement.setAttribute('theme', setTheme);
    },
  },
});
