import { render, staticRenderFns } from "./DrawerFlair.vue?vue&type=template&id=d99d5ba8&scoped=true&"
var script = {}
import style0 from "./DrawerFlair.vue?vue&type=style&index=0&id=d99d5ba8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d99d5ba8",
  null
  
)

export default component.exports