var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"contact","title":"Contact Us"}},[_c('div',{staticClass:"contact-body"},[_c('SectionHeader',[_vm._v("Contact Us")]),_c('form',{staticClass:"contact-content",attrs:{"id":"contact-form","name":"contact-form","method":"post","data-netlify":"true","data-netlify-honeypot":"bot-field"}},[_c('input',{attrs:{"type":"hidden","name":"form-name","value":"contact-form"}}),_c('input',{attrs:{"type":"hidden","name":"bot-field"}}),_c('FormInput',{attrs:{"type":"text","label":"*Name","name":"contactee-name","placeholder":"Your cool name :)","model":_vm.contacteeName,"onBlur":_vm.validateFilled}}),_c('FormInput',{attrs:{"type":"text","label":"*Email Address","name":"contactee-email","placeholder":"No spam, promise!","model":_vm.contacteeEmail,"onBlur":function (s) {
            if (!_vm.validateFilled(s)) {
              _vm.validateEmail(s);
            }
          }}}),_c('Textbox',{attrs:{"label":"*What would you like to talk to us about?","name":"contactee-message","model":_vm.contacteeMessage,"onBlur":_vm.validateFilled,"placeholder":"Anything is fine! Your questions, feedback, suggestions, etc."}})],1),_c('div',{staticClass:"submission-container"},[_c('FormButton',{attrs:{"id":"contact-submission-confirmation-modal-button","linkAction":"non-router","onClick":{
          func: _vm.openModal,
          args: ['contact-submission-confirmation-modal', 'contact-submission-confirmation-modal-button'],
        }}},[_vm._v("Submit")]),_c('FormError',{staticClass:"submission-error",style:(_vm.submissionMsg.type === 'positive' ? 'color: var(--color-accent);' : null)},[_vm._v(_vm._s(_vm.submissionMsg.msg))]),_c('button',{attrs:{"type":"submit","hidden":""}})],1)],1),_c('svg',{staticStyle:{"transform":"translateY(-7px)"},attrs:{"viewBox":"0 0 1440 320","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{staticClass:"slope",attrs:{"d":"m0 128 48 16c48 16 144 48 240 75 96 26 192 48 288 48s192-22 288-22 192 22 288 38 192 26 240 32l48 5v-320h-1440z"}})]),_c('ConfirmationModal',{attrs:{"id":"contact-submission-confirmation-modal","positiveFunc":function () {
        if (!_vm.validateAll()) {
          _vm.submitForm('contact-form');
        }
      }}},[_vm._v("Are you sure you want to submit the form?")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }