<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" id="cityscape" viewBox="0 0 3089.9 1306.4">
      <g class="base">
        <path
          fill="#ebadc2"
          d="M193 1144l2 1c33-3 66-1 98-1h81c17 0 18 1 18 18v355c0 4 0 8-2 12-5 3-11 2-16 2H209c-18 0-19-1-19-19v-254-99c0-5-1-11 3-15z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#404fcf"
          d="M2731 1836l-114-2a3 3 0 012-3c4-2 10-2 15-2h66c11 0 12-1 12-11-2-45-2-90-2-136 0-6-4-11-6-16v-31-4c0-49-1-98 1-147 2-8 8-13 14-18l63-52c2-3 5-6 9-5 14 9 25 21 38 31l36 31c5 4 9 8 9 15v50a254 254 0 010 25v16a101 101 0 010 11v237c0 7-2 11-11 11l-121-1-11 1z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#1023c3"
          d="M443 1216h98l10 1c3 4 3 8 3 13v285a38 38 0 010 8c-2 7-7 8-13 8h-33a18 18 0 00-15 7l-38 49c-2 3-5 6-3 10a21 21 0 001 3l2 2 16 18c3 3 5 6 2 9-5 3-9 2-14 3-5-2-11-3-17-1l-96 1c-6 0-7-5-8-9l-9-21c-5-8-10-8-16 0-4 7-6 14-8 21-2 4-2 9-8 8h-61a13 13 0 01-5-2c-28-29-55-59-81-90-3-3-6-7-1-10a64 64 0 0143-1c4 2 8 1 11 1h185c7 0 10 5 13 9l38 43c2-4 1-8 1-11v-337c0-6-1-12 3-17z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#0014bf"
          d="M65 1832H7c-4 1-6-1-6-5v-787-7c0-4 2-6 5-7l69-24c5 1 5 5 5 9v581a153 153 0 010 17c-1 9-7 14-11 20-2 20-1 40-1 60 0 7-2 13-10 16a25 25 0 00-11 15c-5 18-7 37-4 56 1 9 4 17 14 20 5 7 2 14 3 21 0 4-2 8 4 10 2 1 3 3 1 5z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#94d3b2"
          d="M1019 1728a142 142 0 00-30-2c-18-7-38-6-57-9-16-2-31-7-46-8l-2-2c-4-12 0-23-3-35-2 12 0 24-1 36-2 4-7 5-11 6-6 1-11 4-13 10-6 1-5-3-5-6-3-17-9-32-19-46 1-3 4-5 7-7 6-3 8-8 8-15a40 40 0 0113-30c13-12 31-12 43 0 5 5 8 5 14 3 7-2 14-5 22-4 3 3 1 7 0 10-2 11-4 22-2 33 3 14 7 17 20 20 3 0 6 0 9 2 4 7 1 15 2 22 2-7-1-15 3-22 3-3 7-2 11-3 11-2 16-6 18-17 2-12 0-24-2-36-1-4-4-8-1-12 23-8 43-21 62-34l14-10 4 1c5 8 13 10 22 11l2 2c3 10 0 21 1 31 1-10-1-21 2-31 2-3 5-2 7-3 12-1 16-5 16-17l-1-19c0-3-1-7 3-8l36-10c4 3 3 8 2 12a123 123 0 000 33c2 13 6 18 20 20 3 0 5 1 7 3 4 7 2 14 2 21l1 37 1-41c0-5-1-12 2-17 3-3 7-3 10-3 11-2 15-5 18-16 4-16 0-32 0-48-1-4-2-8 2-12 7-2 15-2 23-2h14c8 0 9 1 4 7-8 11-10 23-11 36-1 14 2 25 16 31 3 7 1 14 2 19 1-6-1-13 2-20 18-8 19-9 19-29 0-16-4-30-15-43a224 224 0 01105 22c23 12 46 23 70 31 6 8 3 17 2 26-1 4-3 3-5 0l-7-11c-5-8-9-8-15 0a62 62 0 00-8 18c-4 13-8 25-8 39 0 3-1 6-4 7-16 1-30 8-43 16-3 2-7 7-11 2-2-7-2-15 0-22 14-6 17-19 16-33 0-16-1-33-7-49-2-5-5-10-11-10s-8 5-10 10c-9 22-10 45-6 68 2 8 9 10 15 14 2 9 1 18 0 27-12 16-27 31-46 39-3 1-7 2-10 0s-4-5-5-9a38 38 0 00-5-14c-7-10-17-9-24 1-2 3-2 10-8 9a37 37 0 00-14-10c-23-11-47-21-73-24-34-3-64 5-91 25a5 5 0 01-4 2c-4-6-3-12-4-18-3-12-5-24-12-35-7-13-15-13-21-1-9 15-14 31-14 48 0 11 2 14 14 15 2 0 5 0 7 2a6 6 0 012 5c-8 10-18 16-30 21z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#404fcf"
          d="M77 1613v-13-585c0-5 2-9-2-13 3-2 5 0 8 0l103 18c9 1 7 3 7 9v492c0 7 0 7 8 7h188a1 1 0 011 2H199l-38-1c-5 0-10 0-14 4-8 5-13 13-18 20l-44 55c-2 2-3 5-8 5z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#c36"
          d="M451 1600c-5-7-4-7 0-13l38-48c5-7 11-11 20-11h32c3 0 7 0 10-2l10-1h63c20 0 20 0 20-20v-184c0-6-1-12 3-16 5 0 9-3 14-2h85c5 0 10 0 14 3 3 4 2 9 2 14 2 64 3 128 2 191 0 6 0 12 5 16 3 5 0 8-2 12l-39 54c-3 4-7 8-12 8l-57 1H467a81 81 0 01-16-2z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#2032c7"
          d="M3089 1111c2 4 1 8 1 12l1 225v481c-18 2-36 0-54 0-3-2-4-6-4-10l-1-49a14 14 0 013-7c13-9 16-21 16-36-1-35-6-70-15-104l-4-14v-441c0-22 1-20-20-20h-155c-6 0-12 1-16-4 4-6 10-4 15-4h160c29 0 51-13 73-29z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#ebadc2"
          d="M2417 1357v-98c0-9 0-9 10-10 32-5 65-4 97-4 5 0 11 0 15 3-2 124 0 249-1 373v8c-2 13-12 20-22 27-5-1-11 0-17-3-3-3-3-8-3-13v-47c1-5-2-8-4-11l-35-45a14 14 0 00-10-5c-40 2-81 2-122 2-5 0-11 1-16-3 2-7 9-6 13-6h77c15 0 15 0 15-14v-137c0-6 0-12 3-17zM2545 1236v-70c0-2-1-6 2-8h125c7 0 10 3 10 10v448c0 4 1 9-3 12-4 2-7-3-10-6s-7-2-11-3c-7 0-14 1-21-1-5-4-10-5-17-5-5 1-11 2-16-3-3-10-3-20 0-30 5-6 12-4 18-4 10-1 12-3 8-13-6-3-11-1-17-2-7 0-10-3-11-10v-6c0-18 1-19 19-19 11-1 13-4 9-13-8-3-17 0-26-3-7-9-4-20-2-29 1-5 6-5 10-5 6-1 12 1 17-2 5-9 3-12-6-13-6 0-13 2-19-2-6-11-5-22-2-33 5-4 11-2 16-3 4 0 8 1 12-1 4-9 2-12-6-13h-5c-6 0-14 3-17-5s-3-17 0-26c1-4 6-4 9-4 6-1 13 1 19-2 4-9 2-12-7-13-7 0-15 3-21-3a59 59 0 010-31c6-6 13-3 20-3 10-1 12-4 7-13-5-3-11-1-17-2-8 0-11-3-12-11v-6c0-17 1-18 19-18 3 0 7 0 10-2 5-9 3-12-6-13-7 0-15 3-21-3-4-15-4-15-20-15h-27c-3 0-7 1-10-3z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#d65c85"
          d="M2197 1832h-18a7 7 0 01-4-4l-1-15v-170c0-6-1-12 2-17 5-4 11-3 17-3h127l38-1a10 10 0 013 1 9 9 0 014 5c3 27 1 53 1 80a898 898 0 01-1 86 51 51 0 002 7 25 25 0 011 3c1 28 1 28-25 27h-8c-5-16-3-31-2-47a6 6 0 014-3c13-4 16-14 15-26-1-30-5-60-13-89a19 19 0 00-1-3c-2-4-3-9-7-9-4 1-5 5-6 9-8 22-11 46-13 69l-1 13c-3 21 1 28 19 37 5 15 2 30 1 45 0 6-6 4-10 4h-113l-11 1z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#404fcf"
          d="M55 1703l1-1c9-4 9-4 9-14v-52c0-3-1-7 2-9a39 39 0 0112-2h140a52 52 0 0112 1l2 2c3 4 3 8 3 12v182a22 22 0 01-1 7 11 11 0 01-2 3l-94 1-72-1c-5-11-2-23-3-35 14-7 14-20 14-33 0-12 0-24-3-36-2-12-7-22-20-25z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#94d3b2"
          d="M1927 1516c4 1 7-2 10-4 17-11 34-21 53-27 13-4 26-5 39 0 14 6 28 3 42 0 21-5 40-13 60-20l30-11c10-3 20-4 29 4a24 24 0 0015 5c6 3 4 7 3 12-1 11-4 23-4 35 0 13 2 15 15 19 3 1 6 1 7 4 3 8 3 15 0 22-17 5-34 3-51 1-4-1-3-5-3-9 1-18-4-35-12-52-7-13-15-14-23 0-8 16-13 32-13 50 0 4 2 8-2 11-7 3-15 1-23 1-3 0-5 2-7 4l-55 60c-6 6-8 13-7 21 0 9 1 19-5 27-5-2-4-6-5-9-2-16-6-32-13-46-2-4-4-9-10-9s-7 5-9 9a154 154 0 00-14 59c0 6 1 12-2 17-6 1-5-4-5-7 0-7-4-10-10-14-7-4-17-4-21-13 11-41 1-81-6-120-2-7-5-13-3-20z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#d65c85"
          d="M2633 1509c4 5 2 11 2 16 0 3-3 4-5 4h-18c-5-1-7 2-7 7v14c0 8 0 9 9 9h14c2 0 4 0 5 2-3 3-8 3-12 2-8 0-14 2-14 11l-2 5v31a5 5 0 011 7c-6 4-13 2-19 2a197 197 0 01-19 0c-10 0-17 2-23 9a11 11 0 01-8 4c-3-2-2-5-2-8v-365c0-4 0-8 4-10 13-8 28-7 38-8-28 2-59 0-91 0-3 0-7 0-9-3 3-2 6-1 10-1h47c4 0 7 1 11-1l47 1c11 0 11 0 11 11v6c2 14 11 18 23 16a8 8 0 017 3c-2 2-5 1-8 1h-13c-9 1-9 1-9 9v13c0 8 0 8 8 8h16c2 0 5 0 6 2-4 4-9 4-14 3-8 0-14 1-14 11l-2 5v31c2 14 10 18 23 16a8 8 0 017 2c-2 3-5 2-8 2h-13c-9 0-9 0-9 9v12c0 9 0 9 9 9h16c7 0 7 1 7 8 0 4 2 8-2 12l-2-4c0-10-6-11-14-11-7 0-12 2-11 10 0 2-1 4-3 5v23c0 3 0 6 2 9a21 21 0 011 6c-1 7 3 9 10 10 6 0 12-2 17 2-2 3-5 2-8 2h-14c-7 0-8 0-8 7 1 8-2 16 2 23a8 8 0 011 2c1 4-2 10 3 12 5 3 11 2 17 1 3 0 4-3 4-5 1-3 0-7 3-9z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#94d3b2"
          d="M2417 1357v162c0 10 0 10-10 10h-88c-3 0-7-1-10 1-7 10-13 20-23 26a14 14 0 01-5 0l-36-1c-16 0-18-2-17-18a16 16 0 011-5c2-3 5-3 8-3 13-2 15-5 15-18 1-19-4-38-10-57-1-4-4-8 1-12 4-10 9-21 16-31 6-9 14-14 25-14 12-1 23-6 34-11l36-16c9-4 19-6 28 0 3 1 7 0 10-1a88 88 0 0025-12z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#d65c85"
          d="M389 1530v-375c0-8 0-9-9-9H207c-4 0-8 2-12-1l6-3c33-2 65 0 98-1h71c14 1 27-2 39-9l20-10a24 24 0 01-10 2H255c-5 0-10 1-14-4l9-1h185c8 1 8 1 8 8v464l-15-17-17-20c-7-8-12-18-22-24zM719 1599c13-16 24-33 36-49l14-19c4-6 8-2 10 1l47 54a10 10 0 012 7c0 26 1 52-1 77l-2 2a106 106 0 00-20 50c-1 5-2 10 0 16 1 6 6 9 12 8 6 0 5-6 5-10l1-12c0-3 1-7 6-7 3 1 4 4 5 7v51c-1 4-2 8-6 8-5 0-5-5-5-8-2-14-2-14-15-8l-76 35c-4 2-10 7-15 0l-1-11v-178c0-5-1-10 3-14z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#404fcf"
          d="M647 1305v218c0 3-2 5-5 5h-81c-4 0-7 0-10-2v-309c8 3 15 10 22 15l64 47c7 5 11 10 10 19v7z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#1023c3"
          d="M3089 1111c0 4-1 7-5 9l-41 20c-4 2-8 2-12 2h-190c-6-6-15-7-23-9-34-7-63 1-85 29-4 5-7 12-14 15-5-2-3-6-2-9 9-15 18-29 31-41 17-16 37-22 59-18 13 3 26 6 38 12 3 2 7 4 12 3-2-4-6-5-8-6-17-9-36-12-54-13s-35 7-49 19c-7 7-15 14-20 23-2 2-4 5-8 6-5-4-3-8-1-13 6-13 15-24 25-34 19-21 44-24 70-18 16 3 32 10 46 19 5 2 9 6 14 6-6-9-25-19-43-25-17-6-35-7-53-4-20 2-34 15-47 30-6 7-10 16-17 23a2 2 0 01-3-1v-5c-2-4 0-7 2-9 10-16 20-31 34-44 17-15 36-19 58-15 30 5 55 19 79 37l5 5a3 3 0 002 0c-26-31-69-49-109-46-16 1-28 9-39 19-9 8-17 16-23 26-2 3-4 7-9 6v-7c-2-4 0-7 2-10 9-15 19-30 33-42 21-19 46-21 73-13 38 12 69 33 92 61-5-6-12-16-21-24a212 212 0 00-28-22c-24-16-50-24-79-22-15 1-28 7-39 17-9 9-18 18-25 29-2 3-3 7-8 5v-3a6 6 0 010-6c13-19 24-40 44-53 15-9 31-12 48-9 33 6 61 22 87 43 17 14 30 32 43 50 3 6 8 5 13 5h134c4 1 8-1 11 2z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#d65c85"
          d="M827 1670v-69a20 20 0 00-5-15l-42-49c-3-3-4-9-11-6-8-2-8-8-8-14v-112c0-33-3-66-2-99 2-4 7-4 11-5a262 262 0 0137-5c-34 3-65 1-97 1-4 0-7 0-9-3 2-2 5-1 8-1l109-1 5 1c4-1 6 1 6 5v364c0 3 2 6-2 8z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#94d3b2"
          d="M1617 1602c5 1 7-3 9-6 7-7 13-15 21-21 13-10 26-15 43-11 14 3 28 2 42 1l57-5a5 5 0 010 5c-12 33-17 68-21 102a18 18 0 01-1 6l-3 2c-12-2-13-1-18 9a114 114 0 00-8 22c-1 4-2 8-7 9a191 191 0 01-65 0c-6-1-12-1-17-6-3-4-3-8-1-12s7-3 11-3c7-1 11-5 11-13 2-25-4-49-14-72-2-5-5-9-10-9s-8 4-10 9a171 171 0 00-14 70c0 9 4 13 14 15 3 0 6 0 8 2a9 9 0 010 10c-2 3-5 3-8 2a330 330 0 01-45-15 7 7 0 01-3-3c-2-8-3-16 0-23 3-3 7-3 10-4 12-1 16-5 18-17v-35c0-3-1-6 1-9zM2731 1836c4-3 8-1 12-1l117-1h7c2 0 4-1 4-4v-8-168c4-1 6 2 9 4 23 23 44 46 62 73 4 5 6 8 13 3 9-5 19-2 29 7a29 29 0 014 7l3 7a30 30 0 012 5c2 9 4 18 1 27-3 13-8 17-22 18 3 7 2 13-2 20-6-1-5-5-5-9l-1-21v26c0 3 2 7-3 9-23 3-47 4-71 5-53 2-106 1-159 1z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#79c89f"
          d="M1927 1516a437 437 0 0117 108c1 11 0 23-7 32-6 10-15 11-25 6-3-6-2-13-2-20v-44 50c0 5 1 10-2 14-6 6-13 3-20 1a18 18 0 01-8-6c-4-10-6-20-8-30-1-23 3-47 6-70 1-4 2-8 5-11l8-32a72 72 0 019-21c6-9 12-9 18 0 4 7 7 15 9 23zM1459 1599v-29c3-28 7-56 14-83 3-10 6-20 11-29 6-10 13-10 19 0 7 11 10 23 13 35 7 32 12 64 11 97 5 10 2 19-5 26-8 8-17 10-27 5-4-5-3-11-3-17v-37c-1 16 2 34-1 52-9 6-16 5-27-3a15 15 0 01-2-2 49 49 0 01-3-6c-1-3-3-6 0-9zM1617 1602c4 15 2 30 2 44-1 15-8 20-23 20h-7c-3-3-3-7-3-12v-43 43c0 4 1 9-2 13-17 6-30 1-34-15s-2-31-1-47l4-7c4-29 8-57 18-84 2-5 4-10 7-13 5-6 9-5 14 0a42 42 0 016 10c6 14 9 28 12 43 4 16 7 32 7 48zM1193 1597h-3c-20 1-26-10-27-27 0-11-1-22 2-32v-9c4-27 8-53 16-78 3-7 5-14 10-20s9-6 14 1c6 6 8 13 10 20 6 18 9 35 12 53l2 13 4 34a74 74 0 01-4 33c-3 8-9 12-18 12h-10c-4-5-3-10-3-15 0-14 1-28-1-42l-1 42c0 5 1 10-3 15zM1765 1674c1-18 4-36 7-54a324 324 0 0111-46 106 106 0 016-14 22 22 0 012-4c7-10 10-10 18 0 7 4 8 11 10 17 7 20 11 41 14 62a16 16 0 010 5 41 41 0 00-1 6c-2 12-5 24 4 34 2 10 1 19-2 28-5 12-18 16-32 11-3-6-2-13-2-19 0-13 1-25-1-38l-1 42c0 5 1 10-2 15-4 3-9 3-14 2a7 7 0 01-5-6 207 207 0 00-12-37 4 4 0 010-4zM2335 1831h22c7 0 8-1 8-7v-23c7-2 14 1 20 3 4 5 2 11 3 16 1-5-1-11 2-16a9 9 0 016-2c11-1 15-8 16-18l2-8a11 11 0 016-2c3-1 5-3 7-6 14-16 32-17 47-1a11 11 0 011 2c2 6 0 12 2 17 1 10 11 12 16 19 1 9 4 19-2 28h-155l-1-1v-1z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#94d3b2"
          d="M1553 1598c-3 14-2 29-2 43a37 37 0 003 14c2 8 8 12 16 12h13c5 5 3 12 2 18-5 6-9 3-14 1-5-3-9-3-15 1-7 7-16 8-25 4-3-3-2-8-3-12-1 4 1 9-3 13-15 3-23 0-32-12-6-19 1-37 0-56a5 5 0 013-4h9c12 0 21-8 22-20l1-10c8 4 16 6 25 8z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#79c89f"
          d="M965 1684l-15-1c-9-2-13-8-15-16-4-15-1-30 3-45l1-4 8-11c11-14 30-15 42-2a29 29 0 016 9 14 14 0 002 2c4 13 7 27 6 40a57 57 0 010 6c-3 17-10 22-27 22h-5c-4-4-2-10-3-15-1 5 2 11-3 15z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#c36"
          d="M241 1120l12 1h182v1l-28 14c-10 7-22 9-34 9l-161-1c-4 0-8 2-11-2 13-8 25-16 40-22z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#b8e2cc"
          d="M1099 1584c-9 3-17 1-22-7l-4-5c2-2 2-5 2-8 0-22 6-42 12-61 2-6 5-11 10-15 5-5 8-5 13 0s7 11 9 18l6 23c1 3 1 7 4 10-2 7 0 14 1 22a43 43 0 01-2 15c-2 9-12 12-23 9-5-6-2-14-3-20v-10c-2 9 3 20-3 30zM2243 1442c-2 3 0 6 1 9 7 20 12 42 11 64 0 14-10 20-23 15-6-5-3-11-4-17v-12c-2 10 3 21-3 31a30 30 0 01-5 0c-16-2-19-6-19-21 0-13 3-25 5-38 1-4 1-7-1-10 4 0 4-4 5-7a94 94 0 018-18c6-10 13-10 19-1 2 2 3 5 6 5z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#94d3b2"
          d="M1831 1637c-4-10-3-21-6-31-3-14-6-28-11-41l-5-9c14 1 28-3 42-5l32-5-8 56v27c-6-2-6-8-9-13-7-13-13-13-22-1l-6 14c-2 3-2 7-7 8z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#fff"
          d="M831 1750v-21c-1-3 2-10-3-10-6 0-3 6-3 10v14c0 7-2 8-9 7-11-1-15-6-15-17a108 108 0 0116-54c2-3 4-6 8-7h7c4 1 6 5 8 9a107 107 0 0113 38l2 6c4 8 3 16-2 22s-14 7-22 3z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#b8e2cc"
          d="M1295 1516l-8 1c15 16 15 36 14 57-1 12-9 17-19 14-4-2-3-6-4-9-1 4 1 8-3 11-15-4-17-7-18-22 0-13 1-25 6-38 2-5 7-7 9-14h-19a6 6 0 015-1c12 0 25-2 37 1z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#414fcf"
          d="M2931 1104c-3-1-4-3-6-6a218 218 0 00-27-31 202 202 0 00-97-53c-22-4-41 3-57 17-10 9-17 20-24 31-4 5-9 10-11 17a23 23 0 011-19 128 128 0 0135-41c17-13 36-16 57-14 40 5 71 25 97 54 13 14 25 28 32 45z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#c36"
          d="M2549 1157c9-5 17-11 26-15h11l114-1c3 0 6 2 9-1a5 5 0 013-1 2 2 0 012 3 56 56 0 01-36 18l-127-1c-2 0-2-1-2-2z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#404fcf"
          d="M2709 1083l5-5c7-13 17-23 28-33a64 64 0 0141-17c39-1 71 14 100 39 11 9 20 20 29 32 3 2 4 5 6 8-3 2-5 0-6-2a200 200 0 00-96-65c-15-4-29-5-44-2a54 54 0 00-22 12c-18 14-31 33-41 53-3-7-1-14 0-20z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#0115bf"
          d="M233 1832a9 9 0 010-2c4-3 9-3 13-3h56c11 0 12-1 12-11v-37c0-7-2-11-8-13-2-1-4-2-3-4 6-3 11-3 16 0 3 3 3 8 3 12v44c0 3-1 7 4 9 16 2 33 0 49 0 2 0 5 0 6-3 2-7 1-13 1-19 1-7-2-11-8-14l-1-3c4-3 9-3 14-1 6 10 2 20 3 30 0 4-1 9 5 10a332 332 0 0053 0c4-1 4-5 4-8v-33c0-14 0-14 14-18h9c5 6 3 12 3 19a151 151 0 010 17v3c0 8 2 16-3 23a3 3 0 000 2l-55 1H243l-10-1z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#404fcf"
          d="M2709 1111c4-3 6-7 8-11a127 127 0 0132-32c17-12 37-13 57-11 26 4 48 15 68 30l19 17a3 3 0 012 5c-2 2-4 1-6 0a29 29 0 01-5-3c-21-18-44-30-70-38-22-7-44-6-64 9a102 102 0 00-21 23c-7 10-15 20-20 31-3-7-1-14 0-20z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#c36"
          d="M701 1294h88c8 0 16 0 24 2l-47 8a25 25 0 00-7 2h-76a64 64 0 01-22-4 324 324 0 0140-8zM2477 1238l68 1h44c-16 4-33 8-50 10l-85-1-11 2c-5 1-11 2-16-1q25-7 50-11z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#7e8add"
          d="M2335 1831v2l-2 1c-25-3-50 0-75-1l-61-1c3-2 7-1 11-1h116c2 0 5-1 5-4 0-15 1-29-1-43l-1-2c-2-15-1-31 0-46 0-3 1-6 5-6a5 5 0 015 3 4 4 0 010 1c1 16 4 33-2 48z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#8190db"
          d="M2961 1830v-37c0-3-1-8 3-8 5 0 3 5 3 8v23c0 3-1 6 2 8 15 3 31 1 46 2h7c5 0 10-1 15 2h54c-1 3-3 3-5 3l-122-1a21 21 0 01-3 0z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#7a85dc"
          d="M2587 1834h-84a6 6 0 01-4-1l1-2c8-3 17-1 25-1 13-1 13-1 13-13a65 65 0 011-9l2-15c0-2 1-5 4-5 4 0 5 2 6 5 2 5 1 10 1 15 1 23-4 21 22 22 4 0 8-1 12 1a2 2 0 011 3z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#8390dd"
          d="M2491 1833v-31c-4-7-3-14-2-21a5 5 0 015-5c3 0 4 3 5 5 2 7 1 14 1 22-1 10 2 20-3 30-2 3-4 2-6 0z"
          transform="translate(-1 -530)"
        ></path>
        <path fill="#c36" d="M2709 1140c-2 4-6 2-9 2h-125c3-3 7-1 10-1h112l12-1z" transform="translate(-1 -530)"></path>
        <path
          fill="#7ebcad"
          d="M2025 1832a17 17 0 010-2l5-2c21-1 42-2 64 1a2 2 0 011 3h-70z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#2939c9"
          d="M65 1797c2 3 2 7 2 11v24h-2c0-1 0-3-2-3-4-11-4-22 0-32z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#7eafbb"
          d="M2095 1832v-1a6 6 0 014-3c18-1 35-2 53 1a2 2 0 011 3h-58z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#404fcf"
          d="M2587 1834v-1c9-6 18-3 28-2a6 6 0 012 2 7 7 0 000 1z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#7abcab"
          d="M2497 1833l1-31a3 3 0 013 1c2 10 3 20-1 29a6 6 0 00-1 1h-2z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#7b9ac7"
          d="M2153 1832a16 16 0 000-1c8-5 16-5 24-1l2 2a200 200 0 01-26 0z"
          transform="translate(-1 -530)"
        ></path>
        <path fill="#2032c7" d="M2711 1140h-2v-4h2a3 3 0 010 4z" transform="translate(-1 -530)"></path>
        <path
          fill="#808adf"
          d="M2549 1157a6 6 0 001 1 1 1 0 01-2 1l-1-1a2 2 0 012-1z"
          transform="translate(-1 -530)"
        ></path>
        <path fill="#94d3b2" d="M997 1616a3 3 0 01-2-2z" transform="translate(-1 -530)"></path>
        <path
          fill="#82cca5"
          d="M1496 1680c7 10 18 11 29 10 6 8 2 16 3 22 2-6-2-15 3-22h9c7 0 13-1 17-6 3-4 6-4 11-2s9 5 15 5c4 4 3 10 3 16v-2c0-4 0-8 3-10l42 13 12 3c5 7 1 15 3 21 2-5-2-13 4-19 24 5 48 8 72 4h11a6 6 0 012 5l-4 37a5 5 0 01-2 4c-3-2-1-6-4-7l-33 17c-22 11-43 23-66 31-22 9-44 7-66 2-20-4-38-12-56-20-28-12-55-28-80-45-24-15-46-13-68 2l-32 24-15 9c-5 4-11 3-17 2a36 36 0 00-9-1c-5-7-3-14-1-22 15-5 17-17 16-31 0-4 0-7 3-9 11-2 21-6 29-15l25-21c3-1 3-5 6-6 14-10 28-18 44-21 3-1 6-2 8 1h1c1 16 8 25 24 28 4 11 1 23 2 33 1-10-2-22 2-33 2-3 5-3 8-3 12-2 13-3 16-16 1-3 1-6 3-8 7-3 14-1 20 3 4 7 1 15 3 23 0 2-1 5 3 5z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#ebadc2"
          d="M2177 1830l-24 1h-58l-70-1v-54a12 12 0 01-3-8c0-24-1-49 1-73v-3a32 32 0 010-8 16 16 0 000-7c0-3 0-6 2-8 3-12 2-24 2-36 0-3 1-6 3-8l16-19 43-46c2-3 4-6 8-5 12 8 23 18 34 28l42 36c2 2 5 4 4 7v204z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#82cca5"
          d="M1077 1691c3-5 6-8 11-11 23-12 48-20 76-20a124 124 0 0136 7c18 6 36 13 52 22 4 2 8 4 7 10 3 3 2 6 2 10a117 117 0 00-1 18c0 14 2 16 15 22a7 7 0 013 2c2 8 4 16-1 23-2-4-5-8-10-10l-31-16c-3-2-6-4-11-4v17c0 3 1 7-2 9-4-3-2-8-2-13-1-11 0-22-3-33a64 64 0 00-5-16c-2-5-5-10-11-10s-7 6-10 10c-4 6-2 15-9 20-7 0-14 1-21 4-23 8-47 14-71 12-15-1-30-2-44-8a7 7 0 012-2c3-6 1-13 1-19 1-4 0-8 2-12a5 5 0 014-3c9 1 16-3 21-9zM831 1750h11c9-2 13-6 13-15v-10c1-10 0-10 9-12l15-5c2-1 3-3 6-2 13-1 26 5 38 7l33 4c12 0 23 1 33 9-18 0-34 6-50 14-24 11-45 26-67 41-15 9-29 19-45 25-12 4-24 5-35 6l-58 6-30 2c-18 0-36 4-55 2-7 0-14 4-21 2-8-13-4-19 12-21 25-4 49-1 73-1l6 2 17-6 79-37c3-2 5-3 10-3v20c0 1 0 3 3 3a3 3 0 003-3v-16-12zM2025 1692c2 6 0 11 0 16v68c-10-2-18-7-27-12-20-12-40-22-62-26a60 60 0 00-11-2c-1-1 0-3 1-4 9-21 4-40-12-55l-4-6 3-9c9 1 17 0 24-6l24 12c6 3 9 6 8 13 0 3-1 7 2 10 7 8 17 7 25 12 4 10 1 20 2 30 1-10-1-20 2-30 6-5 17-4 22-11a3 3 0 013 0z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#94d3b2"
          d="M1911 1671c19 13 27 35 20 54-1 4-4 8-6 11-7 5-15 5-24 4-3-3-2-7-3-11-1 4 1 8-3 11-6 0-12 1-18-1s-10-5-12-11c-5-3-4-9-5-14 0-11 4-21 8-32 3-5 9-8 13-12a34 34 0 0125-3 8 8 0 015 4z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#b8e2cc"
          d="M1729 1758c-1-15 2-30 4-46 4-9 5-18 10-27a67 67 0 014-9c7-9 10-9 18-2 7 14 12 27 14 42l2 3c3 3 3 7 2 12v20c0 14-5 19-19 19h-5c-4-3-3-8-3-13v-24c-2 11 4 25-3 37h-5c-9 1-16-3-19-12z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#fff"
          d="M1183 1728c2-2 2-5 2-8 2-6 3-11 6-16s5-10 12-10c6 1 9 5 12 10 6 11 8 23 8 35v31l-1 7c-2 8-7 12-15 12-4-5-1-12-3-18-3 6 3 14-4 18-10 1-15-3-16-17-2-11-1-22-1-33v-11z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#82cca5"
          d="M1869 1682c2 3 0 4-1 6-5 13-7 27-3 40-21 1-41-1-62 2q-5-6 0-12h10c11 0 18-4 19-15 1-7 4-15 1-23 6-3 11 0 16 1 4 2 4 5 3 8v17c4-8 0-17 6-25 4-1 7-2 11 1z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#81cba5"
          d="M475 1830v-24c4-3 8-2 12-2h40c17 0 22 8 14 24h-20c-15-1-30 2-46 2z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#81cba5"
          d="M621 1824c-22 0-44 4-66 2l-8 2c-4-7-4-15 0-22 5-3 10-2 15-2h48c14 0 18 7 11 20z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#7f94d4"
          d="M1803 1718v47l-2 18a2 2 0 01-2 3 3 3 0 01-3-3 48 48 0 01-1-8v-45q-5-6 0-12v-55c0-4-1-9 4-9 5-1 4 5 4 9v55z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#808edb"
          d="M621 1824c0-21 2-17-17-18l-57 1a2 2 0 011-4c15-4 31-1 46-1l12-1c7-1 13-2 13-11 0-3 2-6 5-6 4 0 5 3 5 6 0 8 5 10 11 11a3 3 0 011 4v1a24 24 0 01-3 0c-11 1-11 1-11 13v5c0 2 0 5-3 5s-3-3-3-5z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#828fdc"
          d="M1753 1770v-30a64 64 0 010-7 3 3 0 013-3c2 0 3 1 3 3v68a41 41 0 010 7 2 2 0 01-3 2l-2-2a46 46 0 01-1-7v-31z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#808ddc"
          d="M547 1804v24a5 5 0 01-6 0v-12c0-10 0-10-10-10h-56a2 2 0 010-1c4-4 9-3 14-4h39c4 0 10 1 10-5l1-18c0-3 1-7 6-7 3 1 4 4 5 7l1 16c0 4-1 8-4 10z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#82caa6"
          d="M1049 1736h-2a76 76 0 01-28-8c8-8 19-13 28-20 6 9 3 19 2 28z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#8491dd"
          d="M1199 1789c3-5 1-9 1-14 0-4-1-9 3-9 5-1 3 5 4 8v38a43 43 0 010 6c0 2-1 4-4 3-2 0-2-2-2-3v-18c0-4 0-7-2-11z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#8291db"
          d="M1277 1774a160 160 0 000-24c-4-4-3-10-2-15 0-2 1-5 4-5s5 2 6 5c2 5 2 11-2 16v21c-1 3-3 6-6 2z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#808fdb"
          d="M1895 1740v-14c0-2 1-4 3-5 3 0 3 3 3 5v35c0 2-1 4-3 4s-3-2-3-4v-21z"
          transform="translate(-1 -530)"
        ></path>
        <path fill="#82cca5" d="M1795 1718v12h-12c-3-3-1-8-2-12z" transform="translate(-1 -530)"></path>
        <path
          fill="#d65c85"
          d="M290 1275h8c3 0 7-1 8 2a16 16 0 010 13c-1 2-3 2-5 2h-19c-6 0-7-2-7-11 0-5 2-7 7-6h8zM290 1256h-5c-9 0-13-5-10-14a5 5 0 016-3h20c5 0 6 3 6 8 0 7-1 9-6 9h-11zM246 1292h-5c-10 0-10 0-10-10 0-6 1-7 8-7h12c10 0 13 4 10 14a4 4 0 01-4 3h-11zM334 1220h-5c-9 0-13-5-10-14 1-3 3-3 5-3h20c3 0 5 2 5 6 1 10 0 11-10 11zM334 1256h-7c-8 0-8 0-8-8 0-9 0-9 9-9h14c6 0 7 1 7 10 0 7 0 7-7 7h-8zM334 1311h5c10 0 13 4 10 13a5 5 0 01-5 4h-20c-4 0-5-2-5-5v-5c0-7 0-7 7-7h8zM246 1347h7c8 0 8 0 8 8 0 9 0 9-8 9h-15c-6 0-7-2-7-11 0-5 2-7 7-7h8zM334 1363h-6c-9-1-9-1-9-9 0-5 1-8 7-8h18c5 0 5 4 5 8s1 9-5 9h-10zM246 1220h-6c-8 0-12-6-8-13 1-2 3-3 5-3h19c7 0 5 5 5 9s0 8-6 7h-9zM246 1256h-6c-9 0-9 0-9-9 0-5 2-7 7-7h18c6 0 5 5 5 9s0 7-5 7h-10z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#404fcf"
          d="M2983 1743c-13-12-20-12-36-1-6-9-11-17-18-25-17-22-36-44-58-63v-68c5-3 10-3 15-3h101c4 0 9 1 14-1-4-3-8-2-12-2h-103c-5 0-10 0-15-3v-16c5-4 10-4 15-4h101c4 0 9 1 14-1-4-3-8-2-12-2h-103c-5 0-10 0-15-3v-15c5-4 10-3 15-3h101c4 0 9 1 14-1-5-3-9-2-13-2h-102c-5 0-10 0-15-4v-16c5-3 10-3 15-3h101c4 0 9 1 13-1-3-3-7-2-11-2h-103c-5 0-10 0-15-4v-16c5-3 10-3 15-3h102c4 0 8 1 13-1-5-3-9-2-14-2h-100c-13 0-17-3-17-11s4-10 18-10h100c4 0 8 1 13-1-5-3-9-2-13-2h-103c-11-1-15-3-15-12 0-6 3-10 10-10a106 106 0 0111-1h97c4 0 8 1 13-1-5-3-9-2-14-2h-103c-7 0-13-1-14-11 0-6 3-11 10-11l12-1h96c4 0 8 1 12-2-4-2-8-1-11-1h-101c-21 1-35-8-47-22-2-6-6-10-12-12-5-2-10-3-15-7-3-2-7-4-5-9 7-3 14 1 20 2 3 0 7 3 8-1s2-8-1-11a8 8 0 00-3-2c-29-11-58-14-83 12a215 215 0 00-22 28c-3 4-5 8-10 9-6-5-3-11-2-17 16-27 37-48 70-53 14-3 29 1 43 4 3 0 7 3 9-1s1-10-4-12c-28-13-60-18-85 8a202 202 0 00-22 25c-2 3-4 7-9 8-6-5-3-11-2-17 7-11 15-20 25-29 25-21 54-23 84-14 4 1 10 7 13-1s0-17-8-20c-35-12-61-8-86 23l-20 27c-1 2-3 5-6 5-6-5-3-11-2-17 9-17 21-30 36-42 21-17 45-18 70-13l7 2c3 0 7 3 9-2 1-5 1-11-4-13-25-11-54-14-77 7-12 11-21 23-30 36-2 4-3 8-9 8-6-4-3-9-2-15a132 132 0 0127-35c24-22 51-27 82-20a27 27 0 004 1c3 0 7 4 9-1 2-6 1-11-5-13-28-11-60-13-86 19l-20 27c-2 3-4 7-9 7-5-4-3-9-2-15 15-27 34-49 65-57 16-3 31-1 47 2 3 1 8 5 10-1 2-5 0-10-5-13a59 59 0 00-11-3c-41-13-63 0-86 30-6 7-9 17-18 21-6-6-3-12-1-18 14-24 32-44 61-50 17-3 34-1 50 3 3 1 8 5 10-1 1-5 0-10-5-12-27-11-59-14-82 9-9 8-16 19-23 29-2 3-4 9-10 8-5-6-5-11 1-17 9-13 19-25 32-35 12-7 24-13 38-13 16 0 30 2 45 6 4 2 8 3 7 8 3 4 8 2 12 2h170c13 0 12-1 12 13v454c-5-1-5-6-8-10-5-6-10-6-14 1-5 8-9 17-11 26-8 34-15 68-14 103 0 4 0 8-2 11a2 2 0 01-3 1z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#ebadc2"
          d="M2548 1159a6 6 0 012-1l131 1c5 4 3 10 3 16v432c1 6-1 13 2 19s11 2 17 4a11 11 0 014 2v2c-7 5-13 3-20 2 5 9 16 16 18 28 8 7 9 16 8 26v60c0 23 3 46 2 69v8c0 4-2 6-6 5h-5l-87 1v-1c-2-2-3-6-3-9 0-49-1-98 1-147 0-5 4-8 8-12 13-13 25-28 39-41-9-4-18 0-26-4a2 2 0 01-1-1v-1h19c15 0 15 0 24 10 2-1 1-4 1-5v-452c0-10 0-10-10-10h-111c-3 0-7 2-10-1z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#d65c85"
          d="M2707 1632h-16c-10 0-10 0-10-10v-450-13l24-13c3-1 7-2 8-5a4 4 0 016-1c-1 4-4 8 0 12 3 6-1 11 0 16-1 3-4 6 0 8-2 6-4 11 0 17 2 4 0 9 0 14-1 5-4 11 0 17 3 8 0 15 0 22-2 5-4 10 0 14 3 6-1 11 0 16-2 5-4 10 0 15 3 6-1 11 0 17-2 6-4 11 0 16 3 4-2 7 0 10-2 6-4 11 0 16 3 8 0 15 0 22-2 6-4 11 0 16v8c-7 6-9 14-9 23v47c0 5 0 10-3 15v151z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#2032c7"
          d="M2809 1352c4 9 13 10 20 14 3 5 9 6 12 10 7 10 17 14 25 21 3 3 7 1 10 1h127c3 1 4 3 4 6s-1 4-4 4h-122a9 9 0 00-9 10 9 9 0 002 4c1 3 4 2 6 2h122c3 0 5 2 5 5 0 4-2 5-5 5h-121c-3 0-6-1-7 3a17 17 0 000 11l3 2a79 79 0 008 0h118c3 1 4 3 4 5s-1 5-4 5h-123c-6 1-7 1-7 7s1 7 8 7h119c3 0 7 0 7 5s-4 5-7 5h-129c1-6-4-8-8-12l-72-62c-3-2-5-5-4-9v-59c1-2 2-5 5-4 6 5 14 7 17 14z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#404fcf"
          d="M2789 1341c0 9 2 19 0 29-1 10 3 21 0 31l2 10c-9 3-14 11-22 17l-47 39-15 14v-67c0-8 2-15 10-18l2-3a283 283 0 0132-30c11-8 23-14 33-22a4 4 0 015 0z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#2133c7"
          d="M2871 1526h126l6 1c3 0 4 2 4 5s-1 4-4 4h-132v-10z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#2132c7"
          d="M2871 1501h132c3 0 4 2 4 5s-1 4-4 4l-6 1h-126zM2871 1577h132c3 0 4 2 4 5s-1 4-4 4l-6 1h-126z"
          transform="translate(-1 -530)"
        ></path>
        <path fill="#93d1b3" d="M2705 1664l-22-30h24v23c0 2 1 5-2 7z" transform="translate(-1 -530)"></path>
        <path
          fill="#2132c7"
          d="M2751 1541h-3c-9-1-9-1-9-9 0-5 1-8 7-8h9c7 1 8 2 8 9s0 8-12 8zM2827 1505h-3c-8-1-11-6-8-13 1-2 1-3 3-3a55 55 0 0118 0c3 1 2 4 2 6 1 9 0 9-9 10z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#ebadc2"
          d="M547 1804c0-2-1-6 2-8l6-2c15-4 19-7 21-22 2-19 0-38-11-55s-29-18-41-2c-8 10-11 21-12 33a169 169 0 000 23c1 15 6 21 22 23 2 0 5 0 7 2-1 8-1 8-8 9h-58v-28c0-3 1-6-2-9-1-8 3-16 3-24 0-17-2-34-4-51-1-4-2-8-1-13 3-12 0-25 1-37l1-15c-3-8-11-13-16-21a57 57 0 00-4-4 19 19 0 00-2-2c2-3 5-2 8-2h260v206h-66l-12 1v-3c7-5 15-9 15-19 1-14 0-28-9-39a29 29 0 00-48 4c-7 11-8 24-7 36 1 10 10 13 15 18-2 2-5 2-7 2z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#0014bf"
          d="M449 1828c-1 3-4 2-6 2h-44c-2 0-4 0-6-2-5-12-3-25-2-38 2-4 6-4 10-4 10-2 13-6 13-17 1-24-5-46-15-68-2-5-4-10-10-10s-8 5-10 9c-7 16-10 32-13 48l-2 18c-1 10 5 16 9 23l2 1c10 2 10 2 10 13v22c0 3-1 5-5 5h-52l-3-2a6 6 0 01-2-4c-1-20-2-40 1-60 2-5 7-4 11-5 10-1 15-6 17-15 5-17 1-34-1-51a379 379 0 00-8-50c-1-4-3-8 0-12 2-3 4-2 6-2h97c1 2 0 4-1 7-13 33-16 69-18 105-1 10-1 22 11 27 14 4 14 4 14 19v30c0 4 0 8-3 11zM305 1764c13 0 12 0 12 12v46c0 8 0 8-8 8h-76v-202h57c3 0 7-1 9 3 4 4 1 9 0 14-5 26-9 52-9 78 0 11-1 22 5 31 2 5 3 11 10 10z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#79c89f"
          d="M293 1758c-7-11-7-22-6-34 0-30 5-60 12-89a32 32 0 000-4c4-9 5-18 10-26 3-5 5-12 12-12 7-1 9 6 12 12 5 8 6 17 10 26 4 20 9 41 11 62 2 16 5 32 2 48s-9 21-26 21l-5 1c-4-4-3-9-3-13v-44 48c0 3 0 6-3 9l-16-1c-4 2-7-1-10-4z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#808adf"
          d="M231 1626c-2 3-5 2-8 2H76c-3 0-6 1-9-1 3-5 8-9 10-14 8-7 14-17 20-25l41-51c2-2 4-8 9-4l53 59 31 34z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#fff"
          d="M435 1771c-7-3-9-10-10-16-2-12-1-25 1-37a406 406 0 0110-63c2-9 5-18 10-26 5-5 9-2 13 2 3 1 4 4 5 7 6 13 10 27 9 42l5 37 1 31a34 34 0 01-6 20l-6 3c-14 1-15 0-15-13v-32 31c0 15-1 16-17 14z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#061ac1"
          d="M473 1680l-6-27-8-22c4-5 9-2 14-3l1 1a227 227 0 010 51h-1z"
          transform="translate(-1 -530)"
        ></path>
        <path fill="#2032c7" d="M451 1600l2 2z" transform="translate(-1 -530)"></path>
        <path
          fill="#fff"
          d="M55 1703c9-2 15 2 18 10a83 83 0 017 25c2 16 2 31-1 46-1 8-6 12-14 13v-1c-6-3-4-9-5-15-1 5 1 11-3 15-11 0-14-3-16-13a145 145 0 014-63c1-7 5-13 10-17z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#7f89df"
          d="M57 1796l1-19c0-2 1-4 3-4s2 3 2 4c1 6-1 13 2 19-3 3-2 6-2 9v24c-4 3-5-1-5-4l-1-18v-11z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#b8e2cc"
          d="M1469 1650c0 5 0 10-2 15-2 8-5 11-13 11h-10c-6-5-3-12-4-18a91 91 0 010-11c-2 9 3 20-3 29-20 0-24-4-24-24v-4c2-1 2-4 2-6a140 140 0 0114-54 30 30 0 015-8c6-6 12-6 17 2l8 16 2 10 2 4c6 13 9 25 6 38z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#fff"
          d="M1355 1644c-10 0-13-3-16-12-3-14-2-28-1-42 0-7 2-14 4-20a39 39 0 015-13c8-11 16-11 22 1 4 7 6 15 8 22 2 17 3 33 0 50-2 10-6 13-16 14-2-2-2-5-2-8l-1-8c-1 6 2 12-3 16zM1077 1691c-4 10-5 12-16 12h-8c-5-7-1-15-3-22-2 6 3 15-3 21l-9 1c-11-1-15-5-15-15 0-13 3-26 8-39 2-6 5-12 9-17 7-10 14-10 21 0 8 12 11 25 14 39a89 89 0 012 19z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#b8e2cc"
          d="M1283 1751v-15c0-2-1-4-3-4-3-1-3 2-3 4v14c-13 3-19-5-20-18 0-11 0-22 2-33l6-12c7-12 19-12 27-1a37 37 0 017 17 24 24 0 002 7v18a32 32 0 01-2 11c-3 10-5 11-16 12z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#8192d9"
          d="M1193 1597v-56c0-4-1-10 5-9 4 0 3 5 3 9v113c0 4 1 10-4 9-5 0-4-5-4-9v-57z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#8391dd"
          d="M1099 1584v-30a44 44 0 011-7l2-2a2 2 0 013 2 40 40 0 010 7v62a66 66 0 010 7c0 2-1 3-3 3a3 3 0 01-3-3v-12-27z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#8490dd"
          d="M1047 1702v-25c0-2 0-5 2-5 3-1 4 2 4 4v52c0 3 0 7-4 8-2-3-2-7-2-11v-16-7z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#8498d6"
          d="M885 1706c-2 1-4 3-6 2-3-6-1-12-2-18v-20c0-3 0-5 4-5 3 0 4 2 4 4v37z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#838fde"
          d="M1355 1644v-20c0-1 0-3 2-3 3 0 4 1 4 4v43a37 37 0 010 4c-1 5-3 6-6 2a256 256 0 000-30z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#818fdb"
          d="M965 1684v-18c0-2 0-5 3-5 2 1 3 3 3 5v43c0 2 0 5-3 5s-3-3-3-5v-25z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#8492dc"
          d="M1275 1590l1-16a3 3 0 012-3 2 2 0 011 0 3 3 0 012 3l1 14-1 23c0 2 0 4-2 4-3 0-4-2-4-4v-21z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#2132c7"
          d="M141 1092h-24c-7 0-8 0-8-7v-5c0-7 0-7 8-7h44c9 0 10 1 10 9 0 10 0 10-10 10h-20z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#2133c7"
          d="M141 1378h23c7 1 7 1 7 8v6c0 4-2 6-6 6h-51c-3 0-5-1-5-5v-4c0-11 0-11 11-11h21z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#2132c7"
          d="M139 1174h23c8 1 9 2 9 10 0 9-1 10-8 10h-47c-5 0-7-1-7-6v-6c0-7 0-7 8-8h22zM140 1448h-24c-5 1-7-1-7-6a55 55 0 000-7c0-4 2-7 6-7h48c7 1 8 2 8 10 0 9-1 10-7 10h-24z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#2032c7"
          d="M139 1244h-23c-6 0-7 0-7-7v-5c0-4 2-6 5-6h52c3 0 5 2 5 5v5c0 8 0 8-8 8h-24z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#d65c85"
          d="M695 1469v-6c0-8 1-9 10-8 5 0 6 2 6 6v18c0 6-2 7-9 7-6 0-7 0-7-7v-10zM747 1470v8c0 7-6 11-13 8-3-1-3-3-3-5v-21c0-4 2-5 5-5 10-1 11 0 11 10v5zM659 1353v-5c0-9 5-12 13-9 3 0 3 2 3 4v21c0 3-2 4-5 4-10 1-11 0-11-9v-6zM747 1354v7c0 7 0 8-8 8-7-1-8-1-8-8v-17c0-4 1-5 6-6 9 0 10 0 10 10v6zM675 1412v6c0 9 0 9-9 9-5 0-7-1-7-7v-18c0-6 4-6 8-5 4 0 8-1 8 5v10zM667 1528c-7 0-8 0-8-7s1-8 7-8c8 0 9 1 9 8s0 7-8 7z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#79c89f"
          d="M2985 1742c-2-40 5-79 15-118a97 97 0 0110-24c6-10 13-10 19 0 3 3 4 7 6 11a488 488 0 0115 69c3 20 4 39 3 59 0 11-4 20-16 23-5 4-10 4-16 1-3-3-3-8-3-12v-43 43c0 4 0 8-2 11-7 6-15 3-22-1l-5-7c-2-1-2-3-2-6l-2-6z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#4150cf"
          d="M3021 1762h16c-2 4-2 7-2 11v41l2 14h-15c-3-2-4-5-4-9v-47c0-4 1-7 3-10z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#404fcf"
          d="M2499 1588v54c0 4-1 8 2 11 2 6 0 13 2 19-2 11 1 23-3 34-3 4-7 4-11 5a33 33 0 01-4 0h-72c-4 0-9 1-12-2-3-4-3-8-3-12v-98c0-5-1-10 4-13l87-1a19 19 0 0110 3z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#1023c3"
          d="M2403 1586c-3 4-2 7-2 11v111a2 2 0 01-3 0 2 2 0 010-1c-3-4-2-9-2-14v-88c0-13-1-13-14-13l-56-1c-6 0-10-4-14-8l-21-19c-3-2-7-5-4-10 7-8 13-17 22-24h104l29-2c3 0 7-1 9 3a4 4 0 011 2 17 17 0 01-2 4l-39 44c-2 2-5 5-8 5z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#c36"
          d="M2635 1618h32c-4 9-11 14-16 20l-16 17a98 98 0 01-18 18c-3 2-7 3-11 3h-92l-10-2c-2-8 3-16 13-20l20-22a5 5 0 011 0c12-15 12-15 32-15l35-1c10-4 21-6 30 2z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#808adf"
          d="M2403 1586l39-44 7-8a3 3 0 002-2 4 4 0 000-1c3-1 5 1 6 3l39 49a31 31 0 013 5h-88c-3 0-6 1-8-2z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#d65c85"
          d="M2495 1388h-4c-9 0-11-2-10-11 1-4 2-6 6-6h15c3 0 5 1 5 4v4c0 9 0 9-9 9zM2495 1442h3c9 1 9 1 9 9s0 9-9 9h-8c-8 0-9-1-9-9 0-7 2-8 8-8h6zM2459 1352h-4c-9 0-13-6-9-14 1-3 3-3 5-3h11c8 0 9 1 9 8 0 9 0 9-12 9zM2459 1388h-4c-9 0-13-7-9-14 2-4 6-4 9-3 5 0 12-3 15 3 2 3 1 8 0 12 0 2-2 2-4 2h-7zM2459 1460h-4c-9 0-11-2-10-11 1-5 3-6 7-6h10c8 0 9 1 9 9s0 8-12 8z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#85c0b5"
          d="M2517 1654l-14 16-2 1c-3-6-2-12 0-18 5 0 11-2 16 1z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#d65c85"
          d="M2603 1325c0-19 0-19 18-19h12a9 9 0 012 3c1 15 1 16-14 16h-18zM2633 1476h-24c-4 0-6-1-6-5s-1-9 2-13h22c7 1 8 1 8 8 0 3 1 7-2 10zM2633 1375h-23c-5 0-7-2-7-7v-12h24c7 1 8 1 8 8 0 3 1 7-2 11zM2603 1427c0-19 0-19 18-19 13 0 12 0 12 12v6zM2635 1618c-5-3-11-1-17-2h-13v-6h23c4 0 7 2 7 7a13 13 0 000 1z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#c36"
          d="M2287 1555c0 3 3 6 5 8 11 7 19 18 31 26 13 7 23 18 35 28 2 2 4 4 3 7-9 3-18 2-27 2h-157l-77-67-3-4 26-1c6-4 13-2 20-2h9c7 0 14-2 21 2h52a517 517 0 0158 0z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#94d3b2"
          d="M2415 1776v6c0 15-8 25-24 22-3-2-2-6-3-10-1 4 0 8-3 10-7 1-14-1-20-3l-1-4c-5-13-4-26-1-39 1-10 5-18 15-23 5-2 4-5 1-8-4-5-12-10-9-16s12-4 18-3c33 3 66 0 99 2v3c-8 15-9 33-11 50a8 8 0 01-3 5 16 16 0 01-2-1c-16-14-32-12-43 4-2 3-3 5-7 5-3-1-4-4-4-8a17 17 0 00-3-10l-1-1a14 14 0 012 2c2 6 4 12 0 17z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#0014bf"
          d="M2361 1624c-8-10-19-17-28-26-4-3-7-5-10-10h66c10 0 10 1 10 10v110c-12 2-24 2-36 1v-83l-2-2z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#79c89f"
          d="M2335 1782v-41-6c0-2-1-4-3-4s-3 2-3 4v30c0 6 1 12-2 17-13 0-15-3-18-16-3-14 0-27 1-41a293 293 0 019-55c2-6 3-11 6-15 4-7 8-7 12 0a56 56 0 016 14 270 270 0 017 37c3 17 5 33 5 50a47 47 0 01-1 11c-3 12-6 15-19 15z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#80c8a7"
          d="M2363 1709l36-1a3 3 0 002 0h75l11 1 1 1h-118c4 10 12 16 19 23-6 4-12 5-17 9-5 5-6 11-9 16z"
          transform="translate(-1 -530)"
        ></path>
        <path fill="#79c89f" d="M2364 1797l1 4a4 4 0 01-1-4z" transform="translate(-1 -530)"></path>
        <path fill="#2032c7" d="M2361 1624l2 2z" transform="translate(-1 -530)"></path>
        <path
          fill="#b8e2cc"
          d="M103 1803c-6-1-9-5-10-10-4-18-3-35 5-51 3-7 7-12 15-12s13 5 16 12c8 16 8 33 5 49-1 6-4 11-11 12-9 2-7-5-9-11 0 8-2 14-11 11z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#2132c7"
          d="M92 1702h-5c-4 0-6-1-6-5v-5c0-7 0-7 7-7h8c5 0 7 1 7 6 1 10 0 11-11 11z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#7f89de"
          d="M103 1803c8-2 8-2 8-9v-7a2 2 0 013-2 3 3 0 013 2 54 54 0 010 9c-1 5 2 7 6 7-4 1-7 4-6 9v13c0 2-1 4-3 4s-3-2-3-4v-11c0-8 0-8-8-11z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#b8e2cc"
          d="M1995 1702c-6 1-11 0-16-2-4-2-7-5-8-9-1-23 2-46 10-68a101 101 0 016-13c2-5 5-9 10-9 6 0 8 4 11 8 5 10 8 20 11 30 2 8 4 17 4 25a5 5 0 002 5v9a3 3 0 010 5v7l-2 3c-4 9-13 9-22 9-5-6-2-13-3-20v-11c-2 10 4 21-3 31z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#fff"
          d="M2143 1554h-20c-3-6-3-13-2-19 3-15 7-28 14-41 3-6 7-12 14-13 8 0 11 7 14 13 7 15 10 30 12 46 0 5 0 10-2 14h-22c-4-5-2-11-3-17-1 6 0 13-5 17z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#8491dc"
          d="M2283 1554h-58v-51-7c1-2 1-4 4-5 2 0 2 3 2 5v16l1 18-1 16c0 5 2 7 7 7h36c3 0 6-2 9 1z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#2132c7"
          d="M615 1367v-8c0-3 0-6 3-7a16 16 0 0113 0c2 1 2 3 2 5v19c0 6-3 7-11 7-5 0-7-3-7-8v-8zM597 1484v6c0 9 0 9-9 9s-9-1-9-9v-11c0-10 4-13 14-10 3 0 4 2 4 5v10zM597 1368v3c0 12-1 12-12 11-4 0-6-1-6-5v-20c0-3 2-5 5-5h7c4 0 6 2 6 6v10zM615 1483v-5c0-9 5-12 14-9a5 5 0 014 5v20c0 3-1 4-4 4l-6 1c-7-1-8-1-8-8v-8zM581 1308v-7c0-8 1-8 9-9 5 0 7 2 7 7v19c0 5-2 6-9 6-6 0-7 0-7-7v-9z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#3f4fcf"
          d="M2719 1140l-6 1-2-1v-4c10-16 20-32 36-43 12-7 24-12 38-13a134 134 0 0194 33c-4 6-8 5-13 2-17-12-36-21-57-25-28-6-53 0-71 25-7 8-14 16-19 25z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#3f4ecf"
          d="M2719 1168c-4-5-2-10 0-16 11-18 25-33 45-43 14-7 29-8 44-5 17 2 34 7 49 15l5 4c-4 6-9 6-15 3-13-8-28-13-44-15-19-3-37 3-51 16a134 134 0 00-23 26l-10 15z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#b8e2cc"
          d="M1643 1697h-7c-11-1-17-6-17-18 0-18 3-35 8-52l6-18a26 26 0 013-6c6-10 14-10 21 1 4 7 7 16 9 25 5 16 8 33 7 51 0 13-4 16-17 17l-6-1c-6-5-3-11-4-17a87 87 0 010-12c-2 9 3 20-3 30z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#808edb"
          d="M1589 1690v33a61 61 0 010 7c0 2-1 3-3 3-2 1-3-1-3-3v-12-52-57c0-3-1-7 3-7s3 4 3 7v57z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#8290dc"
          d="M1643 1697v-28-7c1-2 1-5 4-5 2 0 2 3 2 5v15l1 19a41 41 0 000 12l-1 25c0 2 0 4-2 4-2 1-3-2-3-4l-1-27z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#fff"
          d="M2903 1796c-9 0-12-2-14-10a75 75 0 01-4-23c1-14 2-29 6-42 2-6 4-11 8-15 5-6 13-5 17 1a39 39 0 016 11c6 22 7 44 3 66-2 10-5 12-15 12-5-4-3-10-4-15-1 5 2 11-3 15z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#4150cf"
          d="M2993 1758c7 5 15 5 22 5 3 2 3 5 3 8v49a12 12 0 01-3 8l-43 1c-3 0-4-2-3-5 0-6 3-13 1-20l2-1c17 0 21-9 21-30l-2-15a2 2 0 012 0z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#8490de"
          d="M2903 1796v-19c0-3 1-4 4-4 2 0 2 2 2 3l1 20-1 24v5c0 2-1 4-3 4s-3-2-3-4v-21-8z"
          transform="translate(-1 -530)"
        ></path>
        <path fill="#5b86bc" d="M2985 1742c3 2 2 4 2 6l-4-5z" transform="translate(-1 -530)"></path>
        <path fill="#5f7fc1" d="M2993 1758h-2c-2-1-2-2-2-4a7 7 0 014 4z" transform="translate(-1 -530)"></path>
        <path
          fill="#fff"
          d="M1869 1682h-12a5 5 0 01-2-3l-1-20v14c-1 4-1 8-5 9-5 1-10-1-16-2-8-7-6-16-5-24l1-11 2-4v-4c5-8 7-17 12-24 9-13 17-13 25 1l7 15 6 28a16 16 0 012 13 13 13 0 01-2 3c-3 5-7 7-12 9z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#808fda"
          d="M1911 1671l-4-3a5 5 0 010-6v-59c0-3-1-9 3-9 5 0 3 6 3 9v59c0 3 1 7-2 9z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#82cca5"
          d="M1907 1662v6c-9 0-18 1-26 4v-2c2-3-1-9 6-10 7 3 13 3 20 2z"
          transform="translate(-1 -530)"
        ></path>
        <path fill="#94d3b2" d="M1887 1660c-4 2-3 8-6 10v-14z" transform="translate(-1 -530)"></path>
        <path
          fill="#808adf"
          d="M1496 1680l-1 2c0 3-1 4-3 4s-3-2-3-4v-22-6c-4-11-5-22 0-34v-55l1-7a3 3 0 012-3 3 3 0 011 0 3 3 0 012 3v8l1 54c-1 13 0 26-3 39-1 7 2 14 3 21z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#94d3b2"
          d="M1489 1620v34c-6-2-13-4-20-4l-6-38c7 8 16 9 26 8zM1463 1612a4 4 0 01-2-4 4 4 0 012 4zM1831 1640a4 4 0 01-2 5 4 4 0 012-5z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#fff"
          d="M2473 1768l4-32a65 65 0 0110-26 17 17 0 002-1 14 14 0 0110-2c7 1 10 6 12 12 8 23 9 47 5 71-2 11-7 14-17 12a4 4 0 00-1 0l-1-20c0-2 0-4-3-4s-3 2-3 4v20c-11 0-14-7-16-16-2-5 1-12-2-18z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#7f97d2"
          d="M2455 1812v13a3 3 0 01-4 3 3 3 0 01-3-3 131 131 0 010-27 3 3 0 013-3c3 0 4 2 4 4v13z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#808fdb"
          d="M2385 1804v-14a3 3 0 013-3 3 3 0 013 3v34c0 2 0 4-2 4-3 0-4-2-4-4v-20z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#85cda7"
          d="M2413 1756c5 2 7 5 6 10a16 16 0 002 10h-6c1-6-2-13-2-20z"
          transform="translate(-1 -530)"
        ></path>
        <path fill="#94d3b2" d="M2473 1768c3 6 2 12 2 18-4-5-1-12-2-18z" transform="translate(-1 -530)"></path>
        <path
          fill="#808bde"
          d="M1531 1690v21c0 2-1 4-3 4s-3-2-3-4v-21-14c0-2 0-5 3-5 2 1 3 3 3 5v14z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#b8e2cc"
          d="M373 1789c-9-4-12-11-12-19 1-25 6-49 16-72 3-5 6-11 12-11s10 6 12 11a170 170 0 0116 70c1 15-4 20-20 21h-4c-5-6-2-14-3-20v-10c-2 9 3 20-3 30z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#7d8adc"
          d="M319 1763v-55-8c0-2 2-3 4-3a3 3 0 012 3v128c-2 1-5 2-5-1l-1-8v-56z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#7e88de"
          d="M435 1771c15-1 14 4 14-15v-31a52 52 0 011-7 3 3 0 012-3 3 3 0 013 3v44c0 9 0 9 8 9h4a8 8 0 01-2 1c-10 2-10 2-10 11v40c0 4 0 8-6 5v-45c0-9 0-9-8-10l-6-2z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#7f8ade"
          d="M387 1789v-31a43 43 0 011-7 2 2 0 012-2l3 2a46 46 0 010 7v70c-3 2-6 1-6-3v-36z"
          transform="translate(-1 -530)"
        ></path>
        <path fill="#0014bf" d="M293 1758l10 4 2 2c-2 3-4 3-6 1s-5-4-6-7z" transform="translate(-1 -530)"></path>
        <path fill="#7e8add" d="M3015 1828v-66-57c0-3 0-8 4-8s2 5 2 8v57l1 66z" transform="translate(-1 -530)"></path>
        <path
          fill="#d65c85"
          d="M2499 1802c9 0 14-6 15-15 3-22 2-43-5-65a32 32 0 00-10-15v-26c0-3 0-7 2-10a4 4 0 012-1c3 4 7 2 11 2l103 1v159l-30 1-31-1c-7 0-7 0-7-7v-16c14-5 16-15 16-28-1-10-1-20-5-30-3-6-6-12-14-12s-12 5-14 12a90 90 0 00-6 36c0 11 6 17 15 22v16c1 5-1 8-6 8l-35-1-1-30z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#b8e2cc"
          d="M2541 1809c-12-1-14-4-16-15-3-15-1-29 4-43a18 18 0 010-2c4-7 8-14 17-13 9 0 13 6 16 13 6 15 7 31 4 47-1 10-5 12-17 13v-13c0-3 0-6-4-6-3 0-2 3-2 5-1 5 2 10-2 14z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#828fdd"
          d="M1437 1677v-31a39 39 0 011-7 2 2 0 012-2 1 1 0 011 0l2 2a45 45 0 010 7l1 30-1 31a64 64 0 010 7c0 2-1 3-3 3a3 3 0 01-3-3v-11-26z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#d65c85"
          d="M2094 1782h-44c-7 0-7 0-7-7v-6c0-4 2-6 6-6h91c5 0 7 2 7 6v2c0 11 0 11-11 11h-42z"
          transform="translate(-1 -530)"
        ></path>
        <path fill="#94d3b2" d="M2025 1683v-5q3 2 0 5zM2023 1693l2-3v2z" transform="translate(-1 -530)"></path>
        <path
          fill="#8290dc"
          d="M1995 1702v-36c0-1 0-4 2-4 3-1 4 2 4 4v67a40 40 0 010 7 2 2 0 01-3 2l-2-2a46 46 0 01-1-7v-31z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#8393da"
          d="M1849 1682c3-6 1-13 2-19v-6c0-3-1-7 3-6 3 0 3 3 3 5v26c0 9 0 18-2 27 0 2 1 4-2 4s-4-2-4-5c1-7-1-14 1-21 1-2 0-4-1-5z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#79c89f"
          d="M607 1803c-11-3-16-7-17-17-2-12-1-24 5-35 6-13 16-20 31-19s23 9 29 22c5 10 5 22 4 33-1 9-8 14-18 16-4-1-9 1-12-2s-1-7-2-11c0-2 0-5-3-5-2 0-2 3-3 5 0 4 2 9-2 12l-12 1z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#94d3b2"
          d="M541 1796a120 120 0 01-13 0c-8-1-15-5-17-14-5-23-4-46 9-67 12-19 34-18 47 0 8 12 11 25 12 38 0 8 1 16-1 24-4 14-9 19-24 19h-5c-2-3-2-6-2-9a61 61 0 000-7c0-3 1-7-3-7s-3 4-3 7v16z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#1023c3"
          d="M2719 1372c-3-7-3-14 0-22l24-31c20-21 43-27 70-20l22 8c4 1 7 4 6 9a33 33 0 000 4c0 9 0 9-9 6-17-3-33-7-50-4-20 4-35 15-48 29l-15 21zM2719 1246c-3-7-3-14 0-22 10-16 21-31 35-44a57 57 0 0132-13c16-2 31 1 46 7 6 2 10 6 9 14 0 3 1 6-1 9l-26-5c-32-6-58 5-78 30-7 7-13 15-17 24z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#1023c3"
          d="M2719 1334c-4-3-1-6 0-10 11-17 21-33 36-47 15-13 32-18 52-15a181 181 0 0128 7c4 1 7 3 6 8 0 7 1 14-1 20-4 1-7-1-10-2a143 143 0 00-36-7c-18 0-33 6-48 16-11 8-19 19-27 30z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#2132c7"
          d="M2940 1272h58l7 1c3 0 4 2 4 4s-1 5-4 5h-131c-4 0-5-2-5-5s1-4 4-4l7-1z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#2133c7"
          d="M2937 1308h-64c-3 0-4-2-4-4s1-5 4-5l7-1h124c3 0 5 1 5 5 0 3-2 5-5 5a63 63 0 01-7 0h-60z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#2132c7"
          d="M2939 1257h-62c-4 0-8 0-8-6 0-2 1-4 4-4l7-1h117l6 1c4 0 6 1 6 5s-3 4-6 4z"
          transform="translate(-1 -530)"
        ></path>
        <path fill="#1426c4" d="M2841 1376c-5 0-8-4-12-6v-4c5 2 13 1 12 10z" transform="translate(-1 -530)"></path>
        <path
          fill="#d65c85"
          d="M2666 1743h-2c-9-1-9-1-9-10 0-5 2-7 6-6h7c9 0 9 0 9 9 0 7 0 7-11 7zM588 1666h-4c-7 0-9-1-9-7s2-8 8-9h8c8 1 8 1 8 8s0 8-7 8h-4zM514 1636h-2c-8 0-11-4-9-12 1-2 2-3 4-3h15c2 0 3 1 3 4 2 9 0 11-11 11zM660 1666h-5c-4 1-6-1-6-5-1-9 0-11 10-11h5c3 1 6 0 7 3 1 4 1 8-1 12l-4 1h-6zM513 1666h-6c-2 0-3-1-4-4-1-9 0-11 9-12h4c9 1 9 1 9 8 0 8 0 8-8 8h-4z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#7f89df"
          d="M2458 1628a17 17 0 01-2 0c-3 0-7 2-8-2-2-3-1-7 0-11a3 3 0 013-2h15c2 0 3 2 3 4 2 9 0 11-11 11z"
          transform="translate(-1 -530)"
        ></path>
        <path fill="#94d3b2" d="M2489 1709a2 2 0 01-2 1v-1a3 3 0 012 0z" transform="translate(-1 -530)"></path>
        <path fill="#2032c7" d="M2451 1532a2 2 0 01-2 2z" transform="translate(-1 -530)"></path>
        <path
          fill="#8f97e3"
          d="M2143 1554a129 129 0 002-22c0-1 0-3 2-3 3-1 4 1 4 3v22z"
          transform="translate(-1 -530)"
        ></path>
        <path fill="#85cda7" d="M2413 1756h-1 1z" transform="translate(-1 -530)"></path>
        <path
          fill="#d65c85"
          d="M2605 1579c0-18 0-18 18-18h10c3 4 1 9 2 14 0 3-3 4-6 4z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#1023c3"
          d="M2719 1308c-3-6-2-12 0-17l20-28c7-9 14-17 24-24 14-9 29-12 46-9 9 1 17 4 25 6 6 2 8 5 7 11 0 4 1 8-1 13l-12-3c-15-3-31-6-47-3a66 66 0 00-29 15c-13 11-25 24-33 39zM2719 1207c-4-5-2-10 0-14 8-10 14-21 22-30 13-17 30-26 52-27 15 0 29 4 42 9 4 1 7 3 6 8v6c0 8 0 8-8 6-14-5-28-7-43-7-15 1-29 6-41 15s-21 22-30 33z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#1023c3"
          d="M2719 1276c-3-5-2-10 0-16l28-38c13-14 28-22 47-24 14 0 28 3 41 8 5 2 7 4 6 9 0 4 1 8-1 13l-29-6c-23-4-43 2-61 16-13 11-23 24-31 38z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#2132c7"
          d="M2871 1551h132c3 0 4 1 4 4s-1 5-4 5l-6 1h-126v-10z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#d65c85"
          d="M2633 1273h-23c-5 0-7-2-7-7v-12l24 1c7 0 8 0 8 7 0 4 1 7-2 11zM2633 1509v13c0 3-2 4-5 4h-20c-3 0-5-1-5-5s0-9 2-12z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#1023c3"
          d="M2789 1341c-10 7-20 15-31 20-7 4-13 10-18 16a87 87 0 01-21 16c-2-2 0-3 0-5l27-36c12-13 27-22 45-23 16-1 31 3 46 10 4 1 6 15 3 18-2 2-4 1-7 1a93 93 0 00-24-5c-6-4-13-7-17-13-1-1-2 0-3 1z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#2132c7"
          d="M2940 1374h58l7 1c3 1 4 1 4 3s-1 5-3 6a14 14 0 01-5 0h-125c-2 0-5 1-6-2a5 5 0 011-7 5 5 0 013 0l19-1z"
          transform="translate(-1 -530)"
        ></path>
        <path
          fill="#d65c85"
          d="M695 1353v-6c0-9 1-9 8-9 8 0 8 0 8 8v16c0 5-1 6-6 7-9 0-10-1-10-9v-7zM703 1528c-7 0-8 0-8-7 0-8 1-8 8-8s8 1 8 7c0 8 0 8-8 8zM695 1411v-6c0-8 0-9 8-9s8 1 8 8v14c0 3 1 7-2 8a18 18 0 01-12 0c-2-1-2-3-2-5v-10zM747 1412v6c0 9-1 9-9 9-7 0-7-1-7-8v-18c0-3 2-4 5-5 9-1 11 1 11 10v6zM659 1470v-7c0-8 1-9 9-8 7 0 7 0 7 7v18c0 5-2 6-9 6-6 0-7 0-7-6v-10zM738 1528c-7 0-7-1-7-8s1-8 9-7c6 0 7 1 7 8s-1 7-9 7z"
          transform="translate(-1 -530)"
        ></path>
        <path fill="#79c89f" d="M2936 1299h154v4h-154z"></path>
        <path
          fill="#79c89f"
          d="M487 1833h1526l12-1a11 11 0 002-3 241 241 0 000-51c-1-2-3-4-6-5-18-9-34-20-53-28-14-6-28-11-44-11-7 4-16 2-24 6-3 7-1 15-2 22-1-7 1-15-2-22-12-3-24-4-33-14-15-2-30 0-45 0-5 0-10 0-15 3-4 5-3 11-3 16 0 10 1 21-2 31v-31c0-5 1-11-3-15a12 12 0 00-12 0c-3 3-3 8-3 13 0 20 0 20-19 26a7 7 0 00-2 1c-5 10-2 21-3 30-1-9 2-19-2-29-1-3-5-3-7-3-8 0-13-4-16-11-2-7-6-9-13-5l-28 14c-20 11-39 22-61 30-13 5-27 9-42 7-38-3-73-18-107-35l-58-35a55 55 0 00-56-3c-14 7-26 17-38 27-13 10-25 18-43 14-2 0-5 3-7 0a9 9 0 00-2-3c-14-11-31-17-46-25-6-3-7 2-7 6-1 7 1 14-2 21-1 9-7 15-15 19-3 8-1 16-2 25 0-21 0-21-5-27-9-3-12-9-13-18v-25c0-5 1-11-2-16-8-4-15-2-23 1-37 14-75 16-113 6l-30-9c-9-3-19-2-29-2-19-1-37 6-54 14-25 12-48 29-71 45-17 11-35 21-56 24-41 6-82 8-123 11-18 1-38 0-56 5-4 4-7 1-11-1-20-1-40 3-60 2a23 23 0 00-10 1 13 13 0 01-9 1c-21-1-42-2-63 3a3 3 0 00-1 3l12 1z"
          transform="translate(-1 -530)"
        ></path>
      </g>
      <g class="lights-pink" fill="#d65c85">
        <path
          d="M2495 1298h3c9 1 9 1 9 10 0 8 0 8-8 8h-10c-7 0-8-1-8-8 0-8 1-9 9-9h5zM2459 1424h-4c-9 0-11-2-10-11 1-4 3-6 7-6h10c8 0 9 1 9 9s0 8-12 8zM2603 1204h24c5 0 7 2 8 7v7c0 5-3 7-7 6-16-2-32-1-48-1-5 0-7-2-7-7v-3c0-9 0-9 9-9h21zM660 1636h-2c-9 0-9 0-9-9 0-5 2-7 7-7h7c8 0 9 1 8 9 0 7 0 7-11 7zM291 1311h7c8 0 12 7 8 14-1 3-3 3-6 3h-18c-5 0-7-2-7-7 0-10 0-10 10-10h6zM2095 1688h-42c-10 0-10 0-10-10v-4c0-3 2-5 5-5h94c3 0 5 2 5 5v8c1 5-1 6-6 6h-46z"
          transform="translate(-1 -530)"
        ></path>
      </g>
      <g class="lights-pink" fill="#d65c85">
        <path
          d="M2459 1316h-4c-9 0-11-2-10-11 1-4 3-6 7-6h10c8 0 9 1 9 9s0 8-12 8zM291 1220h-6c-9 0-13-6-9-14a5 5 0 014-3h21c6 0 6 4 6 8s0 9-5 9h-11zM2096 1716h43c8 1 8 1 8 8v4c1 5-2 7-6 7h-89c-9 0-9 0-9-8 0-10 0-10 11-11h42zM246 1311h6c9 0 9 0 9 9 0 8 0 8-7 8h-17c-3 0-6 0-6-4 1-4-2-9 2-13h13z"
          transform="translate(-1 -530)"
        ></path>
      </g>
      <g class="lights-pink" fill="#d65c85">
        <path
          d="M2494 1352h-3c-9 0-11-2-10-11 1-5 3-7 7-6h9c10 0 10 0 10 9s0 8-9 9h-4zM2495 1424h-4c-9 0-11-2-10-11 1-4 2-6 6-6h15c3 0 5 1 5 4v4c0 9 0 9-9 9zM290 1363h-6c-8-1-12-7-8-14 1-2 3-2 5-2h20c5 0 6 2 6 8 0 7-1 7-7 8h-10zM588 1620h3c8 1 8 1 8 10 0 5-2 7-7 6a92 92 0 00-10 0c-5 1-7-2-7-7 0-8 1-8 9-9h4zM333 1292h-6c-8 0-8 0-8-8 0-9 0-9 9-9h13c8 0 8 0 8 7 0 9-1 10-8 10h-8zM2666 1707h-2c-8-1-11-5-9-13 1-3 3-3 5-3h9c8 0 8 0 8 8s0 8-11 8z"
          transform="translate(-1 -530)"
        ></path>
      </g>
      <g class="lights-blue" fill="#2132c7">
        <path
          d="M91 1666h-6c-2 0-4-1-4-4-1-9 0-12 9-12h5c8 1 8 1 8 8s0 8-8 8h-4zM2750 1505h-2c-8-1-13-8-8-14 4-5 10-2 16-2 3-1 6 0 7 4 2 8-1 11-9 12h-4zM2990 1182c-38-3-75-1-113-2a30 30 0 01-5 0c-2 0-3-2-3-4s1-5 3-5a22 22 0 015 0h123a27 27 0 015 0c3 0 4 2 4 5s-1 6-5 6h-14zM139 1143h-23c-6-1-7-1-7-8v-5c0-4 2-6 5-6h52c3 0 5 2 5 5v5c0 8 0 8-8 8h-24z"
          transform="translate(-1 -530)"
        ></path>
      </g>
      <g class="lights-blue" fill="#2132c7">
        <path
          d="M2939 1222h65c3 0 5 1 5 4s-1 5-4 6a30 30 0 01-6 0h-121a46 46 0 01-5 0c-3 0-4-2-4-5s1-4 4-5h7zM2939 1348h65c3 0 5 2 5 5 0 4-2 6-5 5h-130c-4 0-5-2-5-5s2-5 5-5zM125 1666h-1c-11 0-13-2-11-12 2-8 9-5 14-5 5-1 9-1 10 5 1 10-1 12-12 12zM633 1425v6c0 10 0 10-10 10-7-1-8-1-8-8v-16c0-4 2-6 6-7 11 0 12 0 12 12zM2827 1541h-3c-8-1-11-5-8-12 0-2 1-4 3-4a53 53 0 0118 0c3 1 2 4 2 6 1 9 0 9-9 10zM140 1296h-24c-5 1-7-1-7-6a55 55 0 000-7c0-5 2-7 6-7h50c5 0 6 2 6 6v5c0 8-1 9-8 9h-23z"
          transform="translate(-1 -530)"
        ></path>
      </g>
      <g class="lights-blue" fill="#2132c7">
        <path
          d="M2938 1333h-59a63 63 0 01-7-1c-2 0-3-2-3-4 0-3 1-5 4-5a63 63 0 017 0h117a36 36 0 018 0c3 1 4 3 4 5 0 3-2 4-5 4h-24zM2939 1196h64c3 0 6 1 6 5 0 5-3 6-6 5h-129c-4 0-5-2-5-5s2-5 5-4h7zM633 1309v6c0 9-6 12-14 9a5 5 0 01-3-5v-22c0-3 2-4 5-4h7c4 0 5 2 5 5v11zM597 1426v6c0 9 0 9-9 9s-9 0-9-9v-12c0-3-1-7 3-9s8-1 12 0c3 0 3 3 3 5v10zM140 1346h-25c-4 1-6-1-6-5v-2c0-12 0-13 12-13h40c3 0 8-1 9 4a24 24 0 010 14c0 3-3 2-5 2h-25zM140 1498h-23c-8-1-8 0-8-8v-5c0-3 2-4 5-4h52c4 0 5 2 5 5v6c0 5-2 7-6 7l-25-1z"
          transform="translate(-1 -530)"
        ></path>
      </g>
      <g id="cityscape-cloud-one" class="cloud">
        <path
          d="M443.11,1371.19c3.72-2.42,7-4.45,10.14-6.6,29.51-20.14,74.73-6.06,85.51,26.64.59,1.79,1.54,3.54,1.74,5.36.81,7.36,4.39,11.42,12.66,13.14,8,1.69,12.2,7.76,11.33,15.82-.59,5.44,1.55,8.73,6.51,11.72,9.25,5.57,11.27,16.32,5.19,24.74a8.39,8.39,0,0,1-4.94,2.5,50.86,50.86,0,0,1-8.55.36h-313c-14,0-15.15-1-14-14.33,1.27-15.37,4.1-30.24,15.29-42.63,5.92-6.55,12.57-11.05,22-10,7.6.85,9.56-2.77,10.81-9,3-14.91,5.33-30.17,10.64-44.39,16.33-43.72,55.86-53.71,98.93-33.4,22.71,10.69,33.91,30.22,44.11,50.53,1.09,2.18,2.13,4.38,3.27,6.54a29.55,29.55,0,0,0,2.35,3Z"
          transform="translate(-1.37 -530.08)"
          fill="#fff"
          opacity="0.8"
          style="isolation:isolate"
        ></path>
      </g>
      <g id="cityscape-cloud-two" class="cloud">
        <path
          d="M1250.22,1593.12c-24,0-47.92-.06-71.88.06-4.21,0-6.62-1.21-7.13-5.11-.28-2.07-1.07-4.11-1.15-6.18-.49-11.7,6.4-16.65,19-13.74.65.15,1.26.41,1.9.57,6.14,1.51,6.69,1.09,7-4.78.65-11.7,4.15-22.46,13-31.4,14.56-14.7,42.16-11.27,51.64,6.42,3.5,6.55,3.41,6.18,11.61,4.46a31.21,31.21,0,0,1,12.66.23c8,1.74,10.84,7.77,12.3,14.41a23.43,23.43,0,0,1,.44,4c.24,5.22,1.67,9,8.47,10.58,7.22,1.62,10.28,7.25,10.9,13.7.5,5.3-1.5,7.15-7.3,7.16h-61.42Z"
          transform="translate(-1.37 -530.08)"
          fill="#fff"
          opacity="0.8"
          style="isolation:isolate"
        ></path>
      </g>
      <g id="cityscape-cloud-three" class="cloud">
        <path
          d="M2119.71,1528.94H1950.29c-13,0-11.68,1.84-11.4-10.08.19-7.83,4.68-11.73,14-13.08,9-1.31,9.35-2,10.31-9.57,1.12-8.7.56-17.62,5.11-25.9,5.37-9.78,14.35-16,27-17.49,5.47-.64,11.52.71,17,2,9.15,2.08,9.36,2.19,9.37-5.39,0-15.61,8.53-28.58,18.81-40.91s24.86-20,42.45-22.77c18.62-3,35.65.83,50.15,11.29,16.16,11.66,26,26.66,32.59,43.2,3.28,8.17,2.92,8.19,11.32,3.42,11.58-6.58,24.59-6.73,37.49-4.41,24.18,4.35,38.41,17.94,46.62,36.46,1.12,2.54,1.31,5.38,1.76,8.1.57,3.38,2.68,4.92,6.93,5.19a74.61,74.61,0,0,1,13.21,2c11.94,3,17.65,10.28,18.77,20.17.55,4.78.36,9.62.62,14.44.12,2.32-1.22,3.24-3.83,3.31-3.33.09-6.66.32-10,.32H2119.63Z"
          transform="translate(-1.37 -530.08)"
          fill="#fff"
          opacity="0.8"
          style="isolation:isolate"
        ></path>
      </g>
      <g id="cityscape-cloud-four" class="cloud">
        <path
          d="M2869.63,1410.12c3.21,1.72,5.77,3.18,8.46,4.43,7.85,3.7,10.31,9.67,9.11,16.68-.28,1.71-3.11,3.72-5.16,4.26-2.88.73-6.23.19-9.37.19q-161.79,0-323.54.12c-8.17,0-13.29-2.13-17-8.4-14.18-23.58-.09-48.08,30.91-53.23,13.44-2.25,13.1-2.27,14.28-13.53,1.26-11.85,2.25-23.92,13.28-33,15.71-12.94,33.43-12.84,52.3-6,4.1,1.48,8.74,3.81,10.68-2.44,6.73-21.5,27.23-30.62,48.49-39.15,23.82-9.56,67.64,1.41,83.68,26.8a144.24,144.24,0,0,1,11.48,20.34c2.79,6.55,6.87,7.69,14.24,5.94,12.62-3,25.15,1.9,29.43,10.87.91,1.9,1.72,4.55.94,6.23-3.09,6.25-.75,7.78,6.75,8.33,29.59,2.18,41.47,31.3,24.88,45.64-1.71,1.44-2.42,3.67-3.79,5.9Z"
          transform="translate(-1.37 -530.08)"
          fill="#fff"
          opacity="0.8"
          style="isolation:isolate"
        ></path>
      </g>
      <g class="stars-one" fill="#f9f458">
        <path
          d="M109 653l1-1a36 36 0 0010-6 20 20 0 006-8 59 59 0 003-10l1-7 1 2a115 115 0 004 15l1 3a11 11 0 003 4 53 53 0 008 5l4 2v1l-3 1a39 39 0 00-9 6 16 16 0 00-3 4 24 24 0 00-2 5 94 94 0 00-3 11l-1 4a143 143 0 00-4-15l-1-5a13 13 0 00-4-5 35 35 0 00-9-5l-3-1zM2576 962a36 36 0 0011-6 20 20 0 006-8 59 59 0 003-10l1-7 1 2a115 115 0 004 15l1 2a11 11 0 003 5 54 54 0 008 5l4 2-3 2a39 39 0 00-9 6 16 16 0 00-3 3 24 24 0 00-2 6 94 94 0 00-3 11l-1 4v-1a143 143 0 00-4-14l-1-5a13 13 0 00-5-6 35 35 0 00-8-4l-3-2z"
          transform="translate(-1 -530)"
        ></path>
      </g>
      <g class="stars-two" fill="#f9f458">
        <path
          d="M873 1160h1a36 36 0 0010-6 20 20 0 006-9 59 59 0 003-9l1-7 1 2a116 116 0 004 15l1 2a11 11 0 003 5 53 53 0 008 5l4 2-3 2a39 39 0 00-9 6 16 16 0 00-3 3 24 24 0 00-2 5 96 96 0 00-3 11l-1 5v-1a143 143 0 00-4-14l-1-5a13 13 0 00-4-6 35 35 0 00-9-5l-3-1zM2836 561h1a36 36 0 0011-6 20 20 0 006-8 59 59 0 002-10l1-7h1v2a113 113 0 004 15l1 2a11 11 0 003 5 54 54 0 008 5l4 2h1l-4 2a39 39 0 00-9 6 16 16 0 00-3 3 23 23 0 00-2 6 94 94 0 00-3 11v4h-1a4 4 0 000-1 143 143 0 00-3-14l-2-5a13 13 0 00-4-6 36 36 0 00-8-4l-3-2h-1z"
          transform="translate(-1 -530)"
        ></path>
      </g>
      <g class="stars-three" fill="#f9f458">
        <path
          d="M335 756h1a36 36 0 0011-6 20 20 0 006-8 59 59 0 002-10l1-7 1 2a116 116 0 004 15l1 2a11 11 0 003 5 53 53 0 008 5l4 2-3 2a39 39 0 00-9 6 16 16 0 00-3 3 23 23 0 00-2 6 96 96 0 00-3 11l-1 4v-1a146 146 0 00-3-14l-2-5a13 13 0 00-4-6 35 35 0 00-8-4l-3-2h-1zM2326 701a36 36 0 0011-6 20 20 0 006-9 59 59 0 003-10l1-6v-1 3a113 113 0 004 14l1 3a11 11 0 004 4 53 53 0 008 5l4 3-3 1a39 39 0 00-9 6 16 16 0 00-3 4 23 23 0 00-3 5 92 92 0 00-2 11l-1 4v1a4 4 0 000-1 143 143 0 00-4-15l-2-5a13 13 0 00-4-5 36 36 0 00-8-5l-3-1z"
          transform="translate(-1 -530)"
        ></path>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'hero-image',
};
</script>

<style scoped>
#cityscape {
  position: absolute;
  bottom: 0;
  z-index: -1;
}

#cityscape-cloud-one {
  animation: cloud-animation-one 6s linear infinite alternate;
}

#cityscape-cloud-two {
  animation: cloud-animation-two 6s linear infinite alternate;
}

#cityscape-cloud-three {
  animation: cloud-animation-three 6s linear infinite alternate;
}

#cityscape-cloud-four {
  animation: cloud-animation-four 6s linear infinite alternate;
}

@keyframes cloud-animation-one {
  100% {
    transform: translateX(10vw);
  }
}

@keyframes cloud-animation-two {
  100% {
    transform: translateX(-6vw);
  }
}

@keyframes cloud-animation-three {
  100% {
    transform: translateX(-8vw);
  }
}

@keyframes cloud-animation-four {
  100% {
    transform: translateX(8vw);
  }
}
</style>
