<template>
  <section id="schedule" title="Schedule">
    <SectionHeader>Schedule</SectionHeader>
    <div class="schedule-content">
      <ul class="timetable">
        <li>
          <SubHeader>Day One (Saturday)</SubHeader>
        </li>
        <li>
          <Para>Registration</Para>
          <Para>9:00AM</Para>
        </li>
        <li>
          <Para>Opening Briefing</Para>
          <Para>9:30AM</Para>
        </li>
        <li>
          <Para>Sponsor Talk by Indeed</Para>
          <Para>9:45AM</Para>
        </li>
        <li>
          <Para>Start Hacking!</Para>
          <Para>10:30AM</Para>
        </li>
      </ul>
      <ul class="timetable">
        <li>
          <SubHeader>Day Two (Sunday)</SubHeader>
        </li>
        <li>
          <Para>DevPost Submission Check</Para>
          <Para>12:00AM</Para>
        </li>
        <li>
          <Para>Day 2 Check-In</Para>
          <Para>9:00AM</Para>
        </li>
        <li>
          <Para>Continue Hacking!</Para>
          <Para>9:30AM</Para>
        </li>
        <li>
          <Para>Hacking Ends</Para>
          <Para>2:00PM</Para>
        </li>
        <li>
          <Para>Sponsor Talk by GovTech</Para>
          <Para>2:00PM</Para>
        </li>
        <li>
          <Para>Exhibition/Judging</Para>
          <Para>3:00PM</Para>
        </li>
        <li>
          <Para>Prize Presentation Ceremony</Para>
          <Para>5:30PM</Para>
        </li>
      </ul>
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" id="windmillscape" viewBox="0 0 3093.3 1269.5">
      <g class="base">
        <path
          fill="#79c89f"
          d="M0 1173l129-16c129-16 387-48 644-42 258 5 516 47 774 48 257-1 515-43 773-70s516-37 644-42l129-6v224H0zM156 1127l-20-1c-12-3-17-12-20-23-5-20-2-40 4-60l1-5 11-16c15-19 41-20 57-2a39 39 0 018 12 21 21 0 003 3c5 18 10 35 8 54a79 79 0 010 8c-4 22-14 30-36 30h-7c-6-6-3-14-5-21-1 7 3 15-4 21z"
        ></path>
        <path fill="#818fdb" d="M156 1127l1-25c0-3 0-6 3-6s5 3 5 6v59c0 3 0 7-5 6-3 0-3-4-3-6l-1-34z"></path>
        <path fill="#1023c3" d="M668 1157l-72 1 10-379h44l18 378z"></path>
        <path fill="#404fcf" d="M596 1158l-20-22 22-361 7 4-9 379z"></path>
        <path fill="#c36" d="M442 1181l-61 1 8-317 37-1 16 317z"></path>
        <path fill="#d65c85" d="M381 1182l-17-19 18-302 7 4-8 317z"></path>
        <path fill="#c36" d="M854 1242h-47l7-244h28l12 244z"></path>
        <path fill="#d65c85" d="M807 1242l-13-14 14-233 6 3-7 244z"></path>
        <path fill="#2132c7" d="M1234 1082h161v118h-161z"></path>
        <path fill="#1023c3" d="M1395 1082h-161l-49-75h165l45 75z"></path>
        <path fill="#404fcf" d="M1135 1200v-118l50-75 49 75 1 118h-100z"></path>
        <rect width="16.6" height="13.2" x="1152.9" y="1117.7" fill="#2132c7" rx="2.5"></rect>
        <rect width="16.6" height="13.2" x="1196.7" y="1117.7" fill="#2132c7" rx="2.5"></rect>
        <path fill="#2132c7" d="M1271 996h8v37h-8z"></path>
        <path fill="#404fcf" d="M1271 1033l-4-2v-36l4 1v37z"></path>
        <path fill="#2132c7" d="M1269 991h13v9h-13z"></path>
        <path fill="#404fcf" d="M1269 1000l-5-2v-10l5 2v10z"></path>
        <path fill="#1023c3" d="M1282 990h-13l-5-2h13l5 2z"></path>
        <path
          fill="#fff"
          d="M2961 1133c-3 9-4 10-14 10h-6c-5-5-2-12-3-17-1 5 3 11-3 17h-8c-9 0-12-3-12-11-1-11 3-21 7-31 1-4 4-9 7-13 6-8 13-8 18 0 7 9 10 20 13 30a63 63 0 011 15z"
        ></path>
        <path fill="#8490dd" d="M2935 1143v-20c0-2 0-4 2-4 3-1 3 1 3 3l1 21v20c0 2 0 5-4 6-2-3-1-6-2-9v-13-4z"></path>
        <path
          fill="#79c89f"
          d="M2096 1096l-20-1c-12-3-17-11-20-22-5-20-2-40 4-59l2-5 10-16c15-18 40-19 56-2a38 38 0 018 12 19 19 0 003 3c5 17 9 35 8 53a76 76 0 01-1 8c-4 22-13 29-35 29h-7c-6-6-2-14-4-20-2 6 2 14-4 20z"
        ></path>
        <path fill="#818fdb" d="M2096 1096v-25c0-2 0-5 4-5 3 0 4 3 4 5v58c0 3 0 7-4 6-4 0-4-3-4-6v-33z"></path>
        <path
          fill="#b6dbc5"
          d="M204 1152h-2c-14 1-18-7-18-18-1-8-1-15 1-23v-6c3-18 5-35 11-53 2-4 3-9 7-13 3-4 6-4 10 0a248 248 0 0115 50l1 9 2 23a50 50 0 01-3 23c-1 5-6 8-12 8h-6c-3-3-2-7-2-10l-1-29-1 29c0 3 1 7-2 10z"
        ></path>
        <path fill="#8192d9" d="M204 1152v-38c0-3-1-7 3-7 3 0 2 4 2 7v77c0 3 1 6-3 6-3 0-2-4-2-6v-39z"></path>
        <path
          fill="#b8e2cc"
          d="M1450 1197c-1-12 2-25 4-37l7-23a57 57 0 014-7c5-8 8-8 15-1a99 99 0 0111 34l2 2c2 3 2 6 1 10v16c0 12-4 16-15 16h-4c-3-3-3-7-3-10v-21c-1 10 3 21-2 31h-4c-8 1-13-2-16-10z"
        ></path>
        <path
          fill="#828fdc"
          d="M1470 1207v-25a53 53 0 010-6 2 2 0 012-2c2 0 2 1 3 2v56a32 32 0 01-1 6 2 2 0 01-2 1l-2-1a37 37 0 010-6v-25z"
        ></path>
        <path
          fill="#79c89f"
          d="M1523 1150l-13-1c-8-2-12-7-13-14-4-14-2-27 2-40l1-3 7-10c10-13 26-13 37-2a25 25 0 015 8 14 14 0 002 2c4 12 6 23 5 35a49 49 0 010 6c-3 14-8 19-23 19h-5c-4-4-1-9-3-14 0 5 2 10-2 14z"
        ></path>
        <path fill="#818fdb" d="M1523 1150v-16c0-2 0-4 2-4s3 2 3 4v38c0 2 0 4-3 4-2 0-2-2-2-4v-22z"></path>
        <path
          fill="#b8e2cc"
          d="M2325 1196c-1-12 1-25 3-37 3-8 4-16 8-23a56 56 0 014-7c5-7 7-8 14-1a99 99 0 0112 34l1 2c3 3 3 6 2 10v17c0 11-4 15-16 15h-4c-3-3-2-7-2-10v-21c-2 10 3 21-3 31h-4c-7 1-13-2-15-10z"
        ></path>
        <path
          fill="#828fdc"
          d="M2344 1206v-25a53 53 0 011-5 2 2 0 011-3c2 0 3 1 3 3v55a33 33 0 010 6 2 2 0 01-2 2h-1l-1-2a37 37 0 01-1-6v-25z"
        ></path>
        <path
          fill="#fff"
          d="M296 1128c-4 8-5 9-14 9h-7c-4-5-1-12-3-17-1 5 3 12-3 17h-7c-9 0-13-3-13-11 0-11 3-21 7-30l7-14c6-7 13-7 19 0 7 9 9 20 12 30a63 63 0 012 16z"
        ></path>
        <path fill="#8490dd" d="M269 1137l1-20c0-2-1-4 1-4 3 0 4 2 4 4v40c0 2 0 5-3 6-3-2-2-5-2-8l-1-13v-5z"></path>
        <path
          fill="#b8e2cc"
          d="M2712 1035c-2-21 2-40 5-60 5-12 7-25 12-37a91 91 0 017-11c8-12 12-12 23-2 9 17 16 35 19 54l2 4c4 4 4 10 2 15v27c0 19-7 25-24 26h-7c-5-5-4-12-4-17v-33c-2 15 5 33-4 50h-7c-12 0-20-5-24-16z"
        ></path>
        <path
          fill="#828fdc"
          d="M2743 1051v-41a85 85 0 010-9 4 4 0 013-3c3-1 4 1 5 3v90a52 52 0 01-1 9 3 3 0 01-3 2 3 3 0 01-1 0l-2-2a60 60 0 01-1-9v-40z"
        ></path>
        <path
          fill="#79c89f"
          d="M2635 1059l-13-1c-8-2-11-7-13-14-3-14-1-27 3-40l1-3 6-10c10-12 27-13 38-1a25 25 0 015 8 12 12 0 002 1c3 12 6 23 5 36a49 49 0 010 5c-3 14-9 19-24 19h-4c-4-4-2-9-3-14-1 5 2 10-3 14z"
        ></path>
        <path fill="#818fdb" d="M2635 1059l1-16c0-2 0-4 2-4s3 2 3 4v38c0 2 0 4-3 4-2 0-2-2-2-4l-1-22z"></path>
        <path
          fill="#fff"
          d="M2636 1077c-5 12-7 13-20 14h-9c-6-8-2-17-4-24-2 7 4 16-4 23l-11 1c-12-1-17-5-17-16-1-15 4-29 9-42 3-7 6-14 10-19 9-11 18-11 26 0 10 13 14 27 17 42a87 87 0 013 21z"
        ></path>
        <path fill="#8490dd" d="M2599 1090v-28c0-2 0-5 3-5 4 0 4 2 4 5l1 29v27c0 4-1 7-5 9-3-3-3-8-3-12v-18-7z"></path>
        <path
          fill="#b8e2cc"
          d="M2151 1146c-1-12 2-25 3-37l8-23a56 56 0 014-7c5-7 8-8 15-1a99 99 0 0111 34l2 2c2 3 2 6 1 10v17c0 11-4 15-15 15h-4c-3-3-3-7-3-10v-21c-1 10 3 21-3 31h-4c-7 1-12-2-15-10z"
        ></path>
        <path
          fill="#828fdc"
          d="M2171 1156v-25a53 53 0 010-5 2 2 0 012-3c1 0 2 1 2 3l1 8v47a32 32 0 01-1 6 2 2 0 01-2 2l-2-2a37 37 0 010-6v-25z"
        ></path>
        <path
          fill="#fff"
          d="M2173 1182c-3 8-4 9-14 9l-7 1c-4-6-1-12-2-18-2 5 2 12-3 17h-8c-9 0-12-3-12-11-1-10 3-20 6-30 2-5 4-10 8-14 6-7 12-7 18 0 7 9 10 20 12 31a63 63 0 012 15z"
        ></path>
        <path fill="#8490dd" d="M2147 1191v-20c0-1 0-3 2-4 3 0 3 2 3 4l1 21v19c0 3-1 5-4 7-2-3-2-6-2-9v-13-5z"></path>
        <path
          fill="#fff"
          d="M2402 1154c-3 15-5 17-15 17h-7c-5-9-2-20-3-30-2 9 3 21-3 29a75 75 0 01-8 1c-10-1-13-6-14-20a195 195 0 0115-76c7-12 14-12 20 1 8 15 10 34 13 52a171 171 0 012 26z"
        ></path>
        <path fill="#8490dd" d="M2374 1170v-34c0-3 0-6 2-7 3 0 4 3 4 6v70c0 4 0 9-4 11-2-4-2-10-2-15v-22-9z"></path>
        <path
          fill="#b8e2cc"
          d="M2771 1085c-1-14 2-28 4-41l8-25a62 62 0 015-8c5-8 8-8 16-1 6 12 10 24 12 37l2 2c3 3 2 7 1 11l1 18c0 13-5 17-17 18h-5c-3-4-2-8-2-12l-1-22c-1 10 4 22-2 34h-5c-8 0-14-3-17-11z"
        ></path>
        <path
          fill="#828fdc"
          d="M2793 1095v-27a57 57 0 010-6 3 3 0 012-3c2 0 3 2 3 3v61a36 36 0 010 6 2 2 0 01-3 2l-2-2a42 42 0 010-6v-28z"
        ></path>
        <path
          fill="#b8e2cc"
          d="M2206 1103c-1-17 2-34 5-50 3-10 5-21 10-31a74 74 0 015-9c7-10 10-10 19-2 8 14 14 29 16 46l2 2c3 4 3 9 2 13v23c0 15-6 21-21 21h-5c-4-4-3-9-3-14l-1-27c-1 12 5 27-3 41h-5c-10 1-17-4-21-13z"
        ></path>
        <path
          fill="#828fdc"
          d="M2232 1116v-34a73 73 0 011-7 3 3 0 012-3c2 0 3 1 4 3v75a44 44 0 01-1 7 3 3 0 01-3 2l-2-2a49 49 0 01-1-7v-34z"
        ></path>
        <path
          fill="#b8e2cc"
          d="M2879 1112c-2-17 2-34 4-50l10-31a75 75 0 016-9c6-10 10-10 19-2 8 14 13 29 16 46l1 2c4 4 4 9 2 13l1 23c-1 15-6 21-21 21h-5c-5-4-4-9-4-14v-27c-2 12 4 27-4 41h-5c-10 1-17-4-20-13z"
        ></path>
        <path
          fill="#828fdc"
          d="M2905 1125v-34a71 71 0 010-7 3 3 0 013-3c2-1 3 1 3 3v12l1 29-1 33a44 44 0 010 8 3 3 0 01-3 2l-3-2a49 49 0 010-8l-1-33z"
        ></path>
        <path
          fill="#fff"
          d="M2482 1115c-3 9-5 9-14 10h-7c-4-6-1-12-2-18-2 6 2 12-4 17l-7 1c-9-1-12-4-13-12 0-10 3-20 7-30l7-14c7-7 13-7 19 1 7 9 9 19 12 30a63 63 0 012 15z"
        ></path>
        <path fill="#8490dd" d="M2456 1124v-20c0-1-1-3 2-4 2 0 3 2 3 4v40c0 3 0 5-3 7-2-3-2-6-2-9v-13l-1-5z"></path>
        <path
          fill="#79c89f"
          d="M2489 1097c-8-1-17 0-25-2-15-4-22-14-25-28-7-26-3-51 5-75l1-7 13-19c19-24 51-25 72-3a48 48 0 0110 15 25 25 0 003 4c7 22 12 44 10 67a98 98 0 010 10c-5 28-16 37-45 38h-8c-8-8-3-18-6-27-2 9 3 19-5 27z"
        ></path>
        <path fill="#818fdb" d="M2489 1097v-32c0-3 0-7 5-7 4 0 5 3 5 7l1 32-1 41c0 4 0 9-5 8-5 0-5-4-5-8v-41z"></path>
        <path
          fill="#fff"
          d="M2908 1159c-3 8-4 9-14 9h-7c-4-5-1-12-2-17-2 5 2 12-3 17h-8c-9 0-12-3-12-11-1-11 3-21 6-30 2-5 4-10 8-14 6-8 12-8 18 0 7 9 10 20 12 30a63 63 0 012 16z"
        ></path>
        <path fill="#8490dd" d="M2882 1168v-20c0-2 0-4 2-4 3 0 3 2 3 3v41c0 2 0 5-3 6-2-2-2-5-2-9v-13-4z"></path>
      </g>
      <g id="windmillscape-turbine-two">
        <path fill="#404fcf" d="M596 763l45 32-200 250-15-11 170-271z"></path>
        <path fill="#404fcf" d="M632 799l6-54 316 50-1 18-321-14z"></path>
        <path fill="#404fcf" d="M644 746l-51 23-115-300 18-7 148 284z"></path>
        <circle cx="624.6" cy="770.5" r="30.2" fill="#808adf"></circle>
      </g>
      <g class="turbine-one-tower">
        <path fill="#d65c85" d="M381 1182l-17-19 18-302 7 4-8 317z"></path>
        <path fill="#c36" d="M442 1181l-61 1 8-317 37-1 16 317z"></path>
      </g>
      <g class="turbine-three-tower">
        <path fill="#c36" d="M854 1242h-47l7-244h28l12 244z"></path>
        <path fill="#d65c85" d="M807 1242l-13-14 14-233 6 3-7 244z"></path>
      </g>
      <g id="windmillscape-turbine-one">
        <path fill="#d65c85" d="M388 862l41-21 108 246-14 7-135-232z"></path>
        <path fill="#d65c85" d="M428 850l-38-26 160-215 13 8-135 233z"></path>
        <path fill="#d65c85" d="M393 820l-2 46-267-30 1-16h268z"></path>
        <circle cx="404.1" cy="843.9" r="25.3" fill="#ebadc2"></circle>
      </g>
      <g id="windmillscape-turbine-three">
        <path fill="#d65c85" d="M831 1005l3-36 205 29-1 12-207-5z"></path>
        <path fill="#d65c85" d="M840 973l-33 15-76-193 12-5 97 183z"></path>
        <path fill="#d65c85" d="M806 984l29 20-128 162-10-7 109-175z"></path>
        <circle cx="826.2" cy="986.4" r="19.5" fill="#ebadc2"></circle>
      </g>
      <g class="lights-blue" fill="#2132c7">
        <rect width="16.6" height="13.2" x="1152.9" y="1097.1" rx="2.5"></rect>
        <rect width="16.6" height="13.2" x="1196.7" y="1097.1" rx="2.5"></rect>
      </g>
      <path
        class="cloud"
        id="windmillscape-cloud-one"
        fill="#fff"
        d="M268 1042H155c-7 0-11-2-11-8l-2-10c-1-18 10-26 30-21l3 1c9 2 10 1 11-8 1-18 6-35 20-49 23-23 66-18 81 10 5 10 5 10 18 7a49 49 0 0120 0c13 3 17 13 19 23a37 37 0 011 6c0 8 3 14 13 17 12 2 17 11 18 21 0 9-3 12-12 12h-96z"
        opacity=".8"
        style="isolation:isolate"
      ></path>
      <path
        class="cloud"
        id="windmillscape-cloud-two"
        fill="#fff"
        d="M2060 1079h-153c-12 0-11 1-10-9 0-7 4-11 12-12s9-2 10-9c1-8 0-16 4-23 5-9 13-15 25-16 4-1 10 1 15 2 8 2 8 2 8-5 0-14 8-26 17-37s23-18 39-20c16-3 32 0 45 10 14 10 23 24 29 39 3 7 3 7 11 3 10-6 22-6 33-4 22 4 35 16 42 33l2 7c1 3 2 4 6 5a68 68 0 0112 1c11 3 16 10 17 19l1 13c0 2-1 3-4 3h-161z"
        opacity=".8"
        style="isolation:isolate"
      ></path>
      <path
        class="cloud"
        id="windmillscape-cloud-three"
        fill="#fff"
        d="M2819 877l10-7c29-20 75-6 85 27l2 5c1 8 4 12 13 14 8 1 12 7 11 15-1 6 2 9 7 12 9 6 11 16 5 25a8 8 0 01-5 2 51 51 0 01-9 1h-313c-14 0-15-1-14-15 2-15 4-30 16-42 5-7 12-11 22-10 7 1 9-3 10-9 3-15 6-30 11-45 16-43 56-53 99-33 23 11 34 30 44 51l3 6a30 30 0 003 3z"
        opacity=".8"
        style="isolation:isolate"
      ></path>
      <g class="stars-one" fill="#f9f458">
        <path
          d="M2731 242a36 36 0 0011-6 20 20 0 006-9 57 57 0 003-10l1-6v2a116 116 0 004 14l1 3a11 11 0 003 4 53 53 0 008 6l4 2h1l-3 1a40 40 0 00-10 6 17 17 0 00-3 4 24 24 0 00-2 5 96 96 0 00-3 11v5a4 4 0 00-1-1 137 137 0 00-3-14l-2-6a13 13 0 00-4-5 36 36 0 00-8-5l-3-1zM273 31a36 36 0 0011-6 20 20 0 006-9 60 60 0 003-9l1-7v2a116 116 0 004 15l1 2a11 11 0 004 5 51 51 0 008 5l4 2-3 2a39 39 0 00-9 6 16 16 0 00-4 3 23 23 0 00-2 5 96 96 0 00-2 11l-1 5v-1a145 145 0 00-4-14l-2-5a13 13 0 00-4-6 36 36 0 00-8-4l-3-2z"
        ></path>
      </g>
      <g class="stars-two" fill="#f9f458">
        <path
          d="M2058 124a36 36 0 0011-6 20 20 0 006-9 59 59 0 003-9l1-7 1 2a113 113 0 004 15l1 2a11 11 0 003 4 51 51 0 008 6l4 2-3 2a39 39 0 00-9 6 16 16 0 00-3 3 23 23 0 00-2 5 92 92 0 00-3 11l-1 5a4 4 0 000-1 143 143 0 00-4-14l-1-6a13 13 0 00-5-5 36 36 0 00-8-5l-3-1zM640 251a36 36 0 0011-6 20 20 0 006-9 59 59 0 003-9l1-7 1 2a113 113 0 003 15l1 2a11 11 0 004 5 53 53 0 008 5l4 2-3 2a40 40 0 00-9 6 16 16 0 00-3 3 23 23 0 00-2 5 92 92 0 00-3 11l-1 5a4 4 0 000-1 143 143 0 00-4-14l-2-5a13 13 0 00-4-6 36 36 0 00-8-4l-3-2z"
        ></path>
      </g>
      <g class="stars-three" fill="#f9f458">
        <path
          d="M2564 399a36 36 0 0011-6 20 20 0 006-9 59 59 0 003-9l1-7v2a113 113 0 004 15l1 2a11 11 0 004 5 51 51 0 008 5l4 2-3 2a39 39 0 00-9 6 16 16 0 00-3 3 23 23 0 00-3 5 92 92 0 00-2 11l-1 5a4 4 0 000-1 143 143 0 00-4-14l-2-5a13 13 0 00-4-6 36 36 0 00-8-4l-3-2zM1057 666h1a36 36 0 0011-6 20 20 0 006-9 59 59 0 002-10l1-6 1 2a113 113 0 004 15l1 2a11 11 0 003 4 51 51 0 008 6l4 2-3 1a39 39 0 00-9 6 16 16 0 00-3 4 23 23 0 00-2 5 92 92 0 00-3 11l-1 5a4 4 0 000-1 143 143 0 00-3-14l-2-6a13 13 0 00-4-5 36 36 0 00-8-5l-3-1h-1z"
        ></path>
      </g>
    </svg>
  </section>
</template>

<script>
import SectionHeader from '@/components/SectionHeader.vue';
import SubHeader from '@/components/SubHeader.vue';
import Para from '@/components/Para.vue';

import openModalMixin from '@/mixins/openModalMixin';

export default {
  name: 'schedule',
  components: {
    SectionHeader,
    SubHeader,
    Para,
  },
  mixins: [openModalMixin],
};
</script>

<style scoped>
#schedule {
  position: relative;
  padding-top: 30px;
  padding-bottom: 200px;
}

#windmillscape {
  position: absolute;
  bottom: 0;
}

.schedule-content {
  padding: 0 150px;
  display: flex;
  justify-content: space-between;
}

.timetable {
  margin-top: 50px;
  width: 45%;
  display: flex;
  flex-direction: column;
}

.timetable > li:first-child {
  margin-bottom: 30px;
}

.timetable > li:not(:first-child) {
  /* border: 1px solid red; */
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 4fr 1fr;
}

.timetable > li {
  display: flex;
  justify-content: space-between;
}

.timetable > li + li {
  margin-top: 15px;
}

.workshop-button {
  display: inline;
  border-bottom: 2px solid var(--color-accent);
  cursor: pointer;
}

#windmillscape-cloud-one {
  animation: cloud-animation-one 8s linear infinite alternate;
}

#windmillscape-cloud-two {
  animation: cloud-animation-two 6s linear infinite alternate;
}

#windmillscape-cloud-three {
  animation: cloud-animation-three 6s linear infinite alternate;
}

@keyframes cloud-animation-one {
  100% {
    transform: translateX(14vw);
  }
}

@keyframes cloud-animation-two {
  100% {
    transform: translateX(8vw);
  }
}

@keyframes cloud-animation-three {
  100% {
    transform: translateX(-12vw);
  }
}

#windmillscape-turbine-one,
#windmillscape-turbine-two,
#windmillscape-turbine-three {
  opacity: 0.65;
}

#windmillscape-turbine-one {
  transform-origin: 405px 845px;
  animation: turbine-rotate 8s linear infinite;
}

#windmillscape-turbine-two {
  transform-origin: 625px 770px;
  animation: turbine-rotate 10s linear infinite;
}

#windmillscape-turbine-three {
  transform-origin: 825px 985px;
  animation: turbine-rotate 6s linear infinite;
}

@keyframes turbine-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@media (--desktop-narrow) {
  .schedule-content {
    padding: 0 100px;
  }
}

@media (--mobile-wide) {
  .schedule-content {
    flex-direction: column;
  }

  .timetable {
    width: 100%;
  }
}

@media (--mobile-narrow) {
  #schedule {
    padding-bottom: 100px;
  }

  .schedule-content {
    padding: 0 30px;
  }
}
</style>
